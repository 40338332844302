/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, version } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import axios from "../../axiosInstance";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { message, Table, Modal } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditQuestionModal from "./EditQuestionModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import QuestionPaperPDF from "./QuestionPaperPDF";
import AddQuestions from "./AddQuestions";
import { useSelector, useDispatch } from "react-redux";
import { getAllCourse } from "../../redux/actions/userAction";
import SearchIcon from "@mui/icons-material/Search";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditExamQuestion() {
  // Get Data in exam by Id
  let { id } = useParams();
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courseData);
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [passingMarks, setPassingMarks] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [questions, setQuestions] = useState([]);
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [negativeMarking, setNegativeMarking] = useState("");
  // const { user } = useSelector((state) => state.user);
  const [type, setType] = useState("MCQ");
  // const [questionType, setQuestionType] = useState("");
  const [examSet, setExamSet] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentCourse, setStudentCourse] = useState("");
  const [publicExam, setPublicExam] = useState("");
  // eslint-disable-next-line
  const [exam, setExam] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const examStartDate = moment(startDate).format("MM/DD/YYYY");
  const examEndDate = moment(endDate).format("MM/DD/YYYY");

  // Fetch all courses
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setStudentCourse(courses);
    }
  }, [courses]);

  useEffect(() => {
    getAllExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, courses]);

  // Handle Institute Course selection change
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);

    // Assign a fixed ID for "General"
    const generalCourseId = "81af3e57846a42aab058dc4d";

    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setStudentCourseId(selectedCourse._id);
    } else if (selectedCourseName === "General") {
      setStudentCourseId(generalCourseId);
    }
  };

  // Get all Exams
  const getAllExams = async () => {
    try {
      const response = await axios.get(`/api/v1/exams/get-exam-by-id/${id}`);
      const examData = response?.data?.data;
      setExam(examData);
      setSearchResults(examData.questions);
      setName(examData.name);
      setCategory(examData.category);
      setDuration(examData.duration);
      setTotalMarks(examData.totalMarks);
      setPassingMarks(examData.passingMarks);
      setNegativeMarking(examData.negativeMarking);
      setType(examData.type);
      // setQuestionType(examData.questionType);
      setExamSet(examData.examSet);
      setStartDate(examData.startDate);
      setEndDate(examData.endDate);
      setQuestions(examData.questions);
      setInstituteId(examData.instituteId);
      setVendorId(examData.vendorId);
      setPublicExam(examData.publicExam);
      // Set course selection
      const selectedCourse = courses.find(
        (course) => course._id === examData.instituteCourseId
      );

      if (selectedCourse) {
        setStudentCourse(selectedCourse.courseName);
        setStudentCourseId(selectedCourse._id);
      } else if (examData.instituteCourseId === "81af3e57846a42aab058dc4d") {
        setStudentCourse("General");
        setStudentCourseId("81af3e57846a42aab058dc4d");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  // Edit Exam by Id
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to Edit this Exam?",
      content: "This action will update the selected exam details.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const addExam = {
            name,
            category,
            duration,
            totalMarks,
            passingMarks,
            negativeMarking,
            type,
            // questionType,
            examSet,
            startDate: examStartDate,
            endDate: examEndDate,
            id: id,
            instituteId: instituteId,
            vendorId: vendorId,
            instituteCourseId: studentCourseId,
            publicExam,
          };

          const { data } = await axios.put(
            "/api/v1/exams/admin-update-exams",
            addExam
          );

          if (data.success === true) {
            message.success(data.message);
            // Optionally refresh or perform additional actions
            getAllExams();
            // Save exam data to localStorage
            localStorage.setItem("examData", JSON.stringify(data));
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to update exam."
          );
        }
      },
      onCancel() {
        console.log("Exam update canceled.");
      },
    });
  };

  // Delete Exam Questions By id
  const deleteQuestion = async (questionId) => {
    confirm({
      title: "Are you sure you want to delete this Question?",
      content: "Once deleted, this action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.post(
            `/api/v1/question/admin-delete-exam-to-questions`,
            {
              questionId,
              examId: id,
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getAllExams();
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete question."
          );
        }
      },
      onCancel() {
        console.log("Delete action canceled.");
      },
    });
  };

  // Search All Question
  // const searchAllQuestion = async (e) => {
  //   const key = e?.target?.value.toLowerCase();
  //   if (key) {
  //     const filteredQuestions = questions.filter((question) => {
  //       const nameMatch = question?.name.toLowerCase().includes(key);
  //       const optionsMatch = question.options.some((option) =>
  //         option.toLowerCase().includes(key)
  //       );
  //       const correctOptionsMatch = question.correctOption
  //         .map((optIndex) => question.options[optIndex].toLowerCase())
  //         .some((opt) => opt.includes(key));

  //       return nameMatch || optionsMatch || correctOptionsMatch;
  //     });

  //     setSearchResults(filteredQuestions);
  //   } else {
  //     setSearchResults(questions);
  //   }
  // };

  const searchAllQuestion = async (e) => {
    const key = e?.target?.value?.toLowerCase();
    
    if (key) {
      const filteredQuestions = questions.filter((question) => {
        // Check name match
        const nameMatch = question.name.some((item) => {
          const content = item.content || "";
          return content.toLowerCase().includes(key);
        });
  
        // Check options match
        const optionsMatch = question.options.some((option) => {
          const textMatch = option.text?.toLowerCase()?.includes(key);
          const imagesMatch = option.images.some((image) =>
            image?.toLowerCase()?.includes(key)
          );
          return textMatch || imagesMatch;
        });
  
        // Check correctOptions match
        const correctOptionsMatch = question.correctOption
          .map((optIndex) => {
            const correctOption = question.options[optIndex];
            const textMatch = correctOption?.text?.toLowerCase()?.includes(key);
            const imagesMatch = correctOption?.images.some((image) =>
              image?.toLowerCase()?.includes(key)
            );
            return textMatch || imagesMatch;
          })
          .some((match) => match);
  
        return nameMatch || optionsMatch || correctOptionsMatch;
      });
  
      setSearchResults(filteredQuestions);
    } else {
      setSearchResults(questions);
    }
  };
  
  const questionsColumns = [
    {
      title: "#",
      dataIndex: "index",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Box sx={{ display: "flex" }}>
          <IconButton aria-label="delete" onClick={() => deleteQuestion(record._id)}>
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setSelectedQuestion(record);
              setEditQuestionModal(true);
            }}
          >
            <EditIcon sx={{ color: "#1976d2" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      title: "Question",
      dataIndex: "name",
      render: (text, record) => (
        <div>
          {record.name.map((content, index) => {
            if (content.type === "text") {
              return <div key={index} dangerouslySetInnerHTML={{ __html: content.content }} />;
            }
            if (content.type === "image") {
              return (
                <img
                  key={index}
                  src={content.content}
                  alt={`Question ${index}`}
                  style={{ width: "70px", padding: "1px", height: "70px", borderRadius: "50px" }}
                />
              );
            }
            return null;
          })}
        </div>
      ),
    },
    {
      title: "Options",
      dataIndex: "options",
      render: (text, record) => (
        <div>
          {record.options.map((option, index) => (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} key={index}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${String.fromCharCode(65 + index)}: ${option.text || ""}`,
                }}
                style={{ marginRight: "10px" }}
              />
              <div style={{ display: "flex", gap: "5px" }}>
                {option.images.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={image}
                    alt={`Option ${String.fromCharCode(65 + index)} Image ${imgIndex + 1}`}
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    
    {
      title: "Correct Option",
      dataIndex: "correctOption",
      render: (text, record) => (
        <div>
          {record.correctOption.map((optionIndex) => {
            const option = record.options[optionIndex];
            return (
              <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} key={optionIndex}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${String.fromCharCode(65 + optionIndex)}: ${option.text || ""}`,
                  }}
                  style={{ marginRight: "10px" }}
                />
                <div style={{ display: "flex", gap: "5px" }}>
                  {option.images.map((image, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={image}
                      alt={`Correct Option ${String.fromCharCode(65 + optionIndex)} Image ${imgIndex + 1}`}
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ),
    }
  ];
  

  return (
    <div>
      <PageTitle title="Admin Edit Exam | Test Series" />
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Edit Exam And Question
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/exams"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Exams
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            Edit Exams And Questions
          </Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Exam" {...TabPanel(0)} />
            <Tab label="Question" {...TabPanel(1)} />
            <Tab label="Add Question" {...TabPanel(2)} />
          </Tabs>
        </Box>

        {/* Add Exams */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Edit Exam
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="public-exam-label">Public Exam</InputLabel>
                <Select
                  labelId="public-exam-label"
                  id="public-exam"
                  value={publicExam}
                  label="Public Exam"
                  required
                  onChange={(e) => setPublicExam(e.target.value)}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              select
              name="studentCourse"
              label="Institute Course"
              placeholder="Institute Course"
              value={studentCourse || ""}
              onChange={handleChangeStudentCourse}
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled>Select Course</MenuItem>
              <MenuItem key="generalCourse" value="General">
                General
              </MenuItem>
              {courses &&
                courses.map((course) => (
                  <MenuItem key={course._id} value={course.courseName}>
                    {course.courseName}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Exam Name"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Exam Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="category"
              label="Category"
              name="category"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="duration"
              label="Duration (mins)"
              name="duration"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Duration (mins)"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="totalMarks"
              label="Total Marks"
              name="totalMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Total Marks"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="passingMarks"
              label="Passing Marks"
              name="passingMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Passing Marks"
              value={passingMarks}
              onChange={(e) => setPassingMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="negativeMarks"
              label="Negative Marks"
              name="negativeMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Negative Marks"
              value={negativeMarking}
              onChange={(e) => setNegativeMarking(e.target.value)}
            />
            {/* <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="question-type-label">
                  Question Select
                </InputLabel>
                <Select
                  labelId="question-type-label"
                  id="question-type"
                  value={questionType}
                  label="Question Select"
                  onChange={(e) => setQuestionType(e.target.value)}
                >
                  <MenuItem value="single">Single Choice</MenuItem>
                  <MenuItem value="multiple">Multiple Choice</MenuItem>
                  <MenuItem value="combined">Combined Choice</MenuItem>
                </Select>
              </FormControl>
            </Box> */}

            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Exam Set{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={examSet}
                  label="Exam Set"
                  onChange={(e) => setExamSet(e.target.value)}
                >
                  <MenuItem value="Practice Exam">Practice Exam </MenuItem>
                  <MenuItem value="Live Exam">Live Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Update Exam
            </Button>
          </Container>
        </CustomTabPanel>

        {/* Add Questions */}

        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Edit Question
          </Typography>

          {/* Search Input */}
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Question Name Options and Correct Option"
            onChange={searchAllQuestion}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
          <Box>
            <QuestionPaperPDF id={id} />
          </Box>
          <Container
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <Table
              id="questions-table"
              columns={questionsColumns}
              dataSource={searchResults}
              pagination={{ pageSize: 10 }}
              rowKey={(record) => record._id}
              style={{
                width: "100%",
                overflowY: "hidden",
                overflowX: "scroll",
              }}
            />
          </Container>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Add Question
          </Typography>
          <AddQuestions vendor_Id={vendorId} institute_Id={instituteId} />
        </CustomTabPanel>

        {editQuestionModal && (
          <EditQuestionModal
            setEditQuestionModal={setEditQuestionModal}
            editQuestionModal={editQuestionModal}
            examId={id}
            refreshData={getAllExams}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        )}
      </Box>
    </div>
  );
}
