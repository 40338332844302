import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  IconButton,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";

const VideoExamReport = () => {
  const { user } = useSelector((state) => state.user);
  const [videoExams, setVideoExams] = useState([]);
  const [filteredVideoExams, setFilteredVideoExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get all video exams
  const getAllVideoExamsReport = async () => {
    try {
      const response = await axios.post(
        `api/v1/videos/exam/report/admin-get-all-reports-videoExam`
      );
      if (response?.data?.success === true) {
        setVideoExams(response?.data?.data);
        setFilteredVideoExams(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllVideoExamsReport();
  }, []);

  // Filter video exams based on search query
  useEffect(() => {
    let filtered = videoExams;

    if (searchQuery) {
      filtered = filtered.filter((exam) =>
        exam.questionTopics.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredVideoExams(filtered);
  }, [searchQuery, videoExams]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Delete Exam
  const { confirm } = Modal;
  const deleteVideoExam = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Video Exam Report?",
      content:
        "This action will permanently delete the video exam report. Are you sure you want to continue?",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/videos/exam/report/admin-delete-report-videoExam",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getAllVideoExamsReport();
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete video exam."
          );
        }
      },
      onCancel() {
        console.log("Delete action canceled.");
      },
    });
  };

  // Student Video Exam Report
  const generatePDF = (rowData) => {
    confirm({
      title: "Download Video Exam Report?",
      content:
        "Do you want to download the Student Video Exam Report as a PDF?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: async () => {
        const doc = new jsPDF();

        // Set title
        doc.setFontSize(18);
        doc.text("Student Video Exam Report", 14, 20);

        // Add student details
        doc.setFontSize(12);
        doc.text(
          `Student Name: ${rowData.userId?.studentName || "N/A"}`,
          14,
          30
        );
        doc.text(
          `Student Email: ${rowData.userId?.studentEmail || "N/A"}`,
          14,
          40
        );

        // Add question topics (after cleaning the HTML content)
        doc.text("Question Topics:", 14, 50);
        const cleanQuestionTopics = rowData.questionTopics.replace(
          /<\/?[^>]+(>|$)/g,
          ""
        );
        doc.text(
          cleanQuestionTopics || "Please enter a valid question topic.",
          14,
          55
        );

        // Now generate the table content after cleaning HTML for options
        const tableData = rowData.answers.map((answer, index) => {
          const cleanSelectedAnswer =
            answer.selectedAnswer[0]?.replace(/<\/?[^>]+(>|$)/g, "") || "N/A";
          const cleanCorrectAnswer =
            answer.correctAnswer[0]?.replace(/<\/?[^>]+(>|$)/g, "") || "N/A";
          const questionName =
            rowData.videoId.questions[index]?.questionName?.replace(
              /<\/?[^>]+(>|$)/g,
              ""
            ) || "N/A";

          return [
            index + 1,
            questionName,
            cleanSelectedAnswer,
            cleanCorrectAnswer,
            answer.isCorrect ? "Yes" : "No",
          ];
        });

        // Adjust for position after question topics
        const yPos = doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 60;

        // Add the table
        doc.autoTable({
          startY: yPos,
          head: [
            [
              "#",
              "Question Name",
              "Selected Answer",
              "Correct Answer",
              "Is Correct",
            ],
          ],
          body: tableData,
        });

        // Save the PDF with the student's name
        doc.save(`${rowData.userId?.studentName || "Student"}-Exam-Report.pdf`);
      },
      onCancel() {
        console.log("Delete action canceled.");
      },
    });
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteVideoExam(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>

          <IconButton
            aria-label="download"
            onClick={() => generatePDF(value.row)}
          >
            <DownloadIcon sx={{ color: "green" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "studentName",
      headerName: "Student Name",
      width: 150,
      valueGetter: (params) => params.row.userId?.studentName || "N/A",
    },
    {
      field: "studentEmail",
      headerName: "Student Email",
      width: 150,
      valueGetter: (params) => params.row.userId?.studentEmail || "N/A",
    },
    {
      field: "questionTopics",
      headerName: "Question Topics",
      width: 250,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textAlign: "center",
            marginTop: "10px",
          }}
          dangerouslySetInnerHTML={{ __html: params.row.questionTopics }}
        />
      ),
    },
    {
      field: "videoUrl",
      headerName: "Video URL",
      width: 210,
      renderCell: (params) => {
        const url = params.row.videoId?.videoUrl || "#";
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Watch Video
            </Button>
          </a>
        );
      },
    },
    {
      field: "totalQuestions",
      headerName: "Total Questions",
      width: 160,
      valueGetter: (params) => params.row.answers?.length || 0,
    },
    {
      field: "totalCorrectAnswers",
      headerName: "Correct Answers",
      width: 160,
      valueGetter: (params) =>
        params.row.answers?.filter((answer) => answer.isCorrect)?.length || 0,
    },
  ];

  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 140,
      editable: true,
    });
  }

  return (
    <>
      <PageTitle title="Admin Video Exam | Test Series" />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        All Video Exam Report
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/video/exam/list"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Video Exam list
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>
          Video Exam Report
        </Typography>
      </Breadcrumbs>

      <Box
        style={{
          width: "auto",
          overflowY: "hidden",
          overflowX: "scroll",
        }}
      >
        {/* Search Input */}
        <Box sx={{ pb: 2, mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Question Topics"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
        </Box>

        <Paper sx={{ bgcolor: "white" }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              rows={filteredVideoExams.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default VideoExamReport;
