import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { message } from "antd";
import PageTitle from "../Components/PageTitle/PageTitle";
import axios from "../axiosInstance";
import logo from "../assets/TS_Logo1.png";

const AccessPublicUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const currentYear = new Date().getFullYear();

  // Check Server Status
  const checkServerStatus = async () => {
    try {
      const response = await axios.get("/api/server/status");
      if (response?.data?.data === true) {
        // message.success("Server is running");
      } else {
        message.error(
          "Oops! Something went wrong. Please try again later or contact support."
        );
      }
    } catch (error) {
      message.error(
        "Error: Oops! Something went wrong. Please try again later or contact support."
      );
    }
  };

  // Handle Register Api Call
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const userData = {
      name,
      email,
      phone,
    };
    checkServerStatus();
    try {
      const { data } = await axios.post(
        "/api/v1/exams/access-public-user",
        userData
      );
      if (data.success === true) {
        message.success(data.message);
        // Save the user data in localStorage as a string instituteUser
        localStorage.setItem(
          "accessPublicUserData",
          JSON.stringify(data.newUser)
        );
        setTimeout(() => {
          // After successful registration
          localStorage.setItem("hasRegistered", true);
          window.location.reload("/access-public-exam");
        },1000)

      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }finally {
      setLoading(false); // Stop loading
    }
  };


  return (
    <>
      <PageTitle title="Access Public User | Test Series" />
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Home
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>
          Access Public Exam
        </Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mt: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgColor: "primary.white",
        }}
      >
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style needs-validation"
          sx={{
            backgroundColor: "#fff",
            padding: "40px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            maxWidth: "400px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              <img src={logo} width={300} height={190} alt="Test Series Logo" />
            </Box>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              sx={{
                mt: -3,
                fontFamily: "Poppins, sans-serif",
                color: "#333",
                letterSpacing: "2.5px",
                lineHeight: 1.8,
                // fontSize: "16px",
              }}
            >
              Access Public Exam
            </Typography>
            <TextField
              sx={{
                mb: 3,
                mt: 2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Full Name"
              placeholder="Full name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Button
              fullWidth
              disabled={loading}
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
                color: "white",
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>

            <Box
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                pt: 2,
                fontFamily: "Poppins, sans-serif",
                color: "#555",
                letterSpacing: "1.5px",
                lineHeight: 1.8,
                fontSize: "16px",
              }}
              className="switchMember"
            >
              {" "}
              Already a member ?{""} <Link to="/login"> Sign In </Link>{" "}
              <hr />
              <p
                style={{
                  marginTop: "20px",
                  fontSize: "12px",
                  letterSpacing: ".1rem",
                  lineHeight: "1.5rem",
                  marginBottom: "-20px",
                }}
              >
                &copy; {currentYear} Copyright by GatikAI Technologies . All
                rights reserved.
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AccessPublicUser;
