import "./App.css";
import "./stylesheets/theme.css";
import "./stylesheets/alignments.css";
import "./stylesheets/textelements.css";
import "./stylesheets/custom-components.css";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { message } from "antd";
import axios from "./axiosInstance";
import {
  reloadUser,
  allUsers,
  instituteReloadUser,
} from "./redux/actions/userAction";
import store from "./redux/store";
import NotFound from "./pages/Error";
import Login from "./pages/Login";
import Register from "./pages/Register";
import StudentDashboard from "./user/StudentDashboard.js";
import Home from "./pages/Home";
import WriteExam from "./exams/WriteExam";
import About from "./pages/About";
import Exam from "./pages/Exam";
import SubjectiveExam from "./pages/SubjectiveExam";
import SubjectiveWriteExam from "./exams/SubjectiveWriteExam";
import Notes from "./pages/Notes";
import Videos from "./pages/Videos";
import VideoExam from "./pages/VideoExam"
import StartVideoExam from "./pages/StartVideoExam.js";
import StartVideo from "./pages/StartVideo";
import Contact from "./pages/Contact";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import RefundPolicy from "./pages/RefundPolicy"
import Profile from "./user/Profile";
import Reports from "./user/Reports";
import TopRank from "./user/TopRank";
import Layout from "./admin/global/Layout";
// Admin Routes
import AdminDashboard from "./admin/Dashboard";
import AdminTransactions from "./admin/Transactions/Transactions"
import AdminPaymentDetails from "./admin/Transactions/PaymentDetails"
import AdminRoles from "./admin/Roles/Roles";
import AdminPermissions from "./admin/Permissions/Permissions";
import AdminProfile from "./admin/Profile";
import AdminExams from "./admin/Exams/Exams";
import AdminPublicExams from "./admin/Exams/AccessPublicUser/PublicExams";
import AdminPublicExamReports from "./admin/Exams/AccessPublicUser/PublicExamReports.js";
import AdminAddExamQuestion from "./admin/Exams/AddExamQuestion";
import AdminEditExamQuestion from "./admin/Exams/EditExamQuestion";
import AdminReports from "./admin/Exams/Reports.js";
import AdminShareReport from "./admin/Exams/ShareReport.js";
import AdminViewUserReports from "./admin/Exams/ViewUserReports.js";
import AdminShareReportPreview from "./admin/Exams/ShareReportPreview.js";
import AdminStudentShareReportAll from "./admin/Exams/StudentShareReportAll";
import AdminStudentShareGroupReport from "./admin/Exams/StudentGroupReportShare.js";
import AdminStudentGroupReportSharePreview from "./admin/Exams/StudentGroupReportSharePreview.js";
import AdminEvaluation from "./admin/SubjectiveExam/Evaluation";
import AdminEvaluationStudentsList from "./admin/SubjectiveExam/EvaluationStudentsList";
import AdminEvaluationDetails from "./admin/SubjectiveExam/EvaluationDetails";
import AdminEditEvaluationDetails from "./admin/SubjectiveExam/EditEvaluationDetails";
import AdminSubjectiveReports from "./admin/SubjectiveExam/SubjectiveReports";
import AdminSubjectiveTopRank from "./admin/SubjectiveExam/SubjectiveTopRank";
import AdminSubjectiveShareReport from "./admin/SubjectiveExam/SubjectiveShareReport.js";
import AdminSubjectiveShareReportPreview from "./admin/SubjectiveExam/SubjectiveShareReportPreview.js";
import AdminTopRank from "./admin/Exams/TopRank";
import AdminContact from "./admin/Contact";
import AdminCreateVendor from "./admin/Vendor/CreateVendor";
import AdminEditorVendor from "./admin/Vendor/EditorVendor";
import AdminCategoryPermissions from "./admin/CategoryPermissions/CategoryPermissions";
import ConnectionStatus from "./Components/ConnectionStatus.js";
import AdminFiles from "./admin/Files/Files";
import AdminVideos from "./admin/Videos/Video";
import AdminVideoExam from "./admin/VideoExam/AddVideoExam.js";
import AdminVideoExamList from "./admin/VideoExam/VideoExamList.js"
import AdminEditVideoExam from "./admin/VideoExam/EditVideoExam.js";
import AdminVideoExamReport from "./admin/VideoExam/VideoExamReport.js";
import AdminStudentQuestion from "./admin/VideoExam/StudentQuestion.js"
import AdminEditStudentQuestion from "./admin/VideoExam/EditStudentQuestion.js"
import AdminInstituteInfo from "./admin/InstituteInfo/InstituteInfo";
import AdminAddTeachers from "./admin/InstituteInfo/AddTeachers.js";
import AdminJobNotification from "./admin/InstituteInfo/JobNotification";
import AdminFacilities from "./admin/InstituteInfo/Facilities.js";
import AdminInstituteEnquiry from "./admin/InstituteInfo/InstituteEnquiry";
import AdminSubjectiveExam from "./admin/SubjectiveExam/SubjectiveExam";
import AdminAddSubjectiveExamQuestion from "./admin/SubjectiveExam/AddSubjectiveExamQuestion";
import AdminEditSubjectiveExam from "./admin/SubjectiveExam/EditSubjectiveExam";
import AdminAllInstituteStudent from "./admin/Batch/AllInstituteStudent.js";
import AdminPlans from "./admin/VendorPlans/Plans";
import AdminAssignVendorPlanList from "./admin/VendorPlans/AssignVendorPlans/AssignVendorPlanList";
import AdminNewBatch from "./admin/Batch/AddBatch";
import AdminStudentBatch from "./admin/Batch/StudentBatchList";
import AdminAddCourses from "./admin/InstituteInfo/AddCourses.js";
import AdminNewCourseRequest from "./admin/NewCourseRequest";
import AdminNewCourseRequestTableAddBatch from "./admin/NewCourseRequestTableAddBatch.js";
import AdminPublicUser from "./admin/PublicUser.js";
import AdminPublicUserTableAddBatch from "./admin/PublicUserTableAddBatch.js";
import AdminContactSupport from "./admin/ContactSupport.js";
import AdminContactSupportList from "./admin/ContactSupportList.js"
// Sub-Admin & Other Roles
import AdminSubEditorVendor from "./admin/SubAdmin/SubEditorVendor";
import AdminSubCategoryPermissions from "./admin/SubAdmin/SubCategoryPermissions/SubCategoryPermissions";
import AdminSubRoutesPermissions from "./admin/SubAdmin/SubRoutesPermissions/SubRoutesPermissions";
// Test Series Demo Pages
import DemoHome from "./Components/TestSeriesInformation/DemoHome";
import DemoAbout from "./Components/TestSeriesInformation/DemoAbout";
import BookADemo from "./Components/TestSeriesInformation/BookADemo";
import DemoContact from "./Components/TestSeriesInformation/DemoContact";
import DemoPlan from "./Components/TestSeriesInformation/DemoPlan";
import InstitutesInformation from "./Components/TestSeriesInformation/InstitutesInformation.js";
import DemoPrivacyPolicy from "./Components/TestSeriesInformation/DemoPrivacyPolicy.js";
import DemoTermsOfService from "./Components/TestSeriesInformation/DemoTermsOfService.js";
import DemoRefundPolicy from "./Components/TestSeriesInformation/DemoRefundPolicy.js";
// PhonePe Payment
import PhonePe from "./Components/Payment/PhonePe";
import PaymentSuccess from "./Components/Payment/PaymentSuccess";
import PaymentFailure from "./Components/Payment/paymentFailure";
import PaymentPending from './Components/Payment/PaymentPending.js';

// Public Student Dashboard
import AccessPublicUser from "./accessPublicUser/AccessPublicUser.js";
import AccessPublicExam from "./accessPublicUser/AccessPublicExam.js";
import WriteAccessPublicExam from "./accessPublicUser/WriteAccessPublicExam.js";


function App() {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const instituteUser = JSON.parse(localStorage.getItem("instituteUser"));
  const accessPublicUserDataString = localStorage.getItem(
    "accessPublicUserData"
  );
  const accessPublicUserData = accessPublicUserDataString
    ? JSON.parse(accessPublicUserDataString)
    : null;

  // Disable Right-click and Disable Copy
  useEffect(() => {
    // const disableCopy = (e) => {
    //   e.preventDefault();
    //   // message.warning("Copying is disabled on this website.");
    // };

    const disableContextMenu = (e) => {
      e.preventDefault();
      // message.warning("Right-click is disabled on this website.");
    };

    // document.addEventListener('copy', disableCopy);
    document.addEventListener('contextmenu', disableContextMenu);

    return () => {
      // document.removeEventListener('copy', disableCopy);
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  // Check Server Status
  const checkServerStatus = async () => {
    try {
      const response = await axios.get("/api/server/status");
      if (response?.data?.data === true) {
        // message.success("Server is running");
      } else {
        message.error(
          "Oops! Something went wrong. Please try again later or contact support."
        );
      }
    } catch (error) {
      // message.error("Error: Unable to connect to the server");
      message.error(
        "Error: Oops! Something went wrong. Please try again later or contact support."
      );
    }
  };

  useEffect(() => {
    checkServerStatus();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      store.dispatch(reloadUser());
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("studentToken");
    if (token) {
      store.dispatch(instituteReloadUser());
    }
  }, []);

  useEffect(() => {
    store.dispatch(allUsers());
  }, []);

  const AdminDashboardHDC = Layout(AdminDashboard);
  const AdminTransactionsHDC = Layout(AdminTransactions);
  const AdminPaymentDetailsHDC = Layout(AdminPaymentDetails);
  const AdminRolesHDC = Layout(AdminRoles);
  const AdminPermissionsHDC = Layout(AdminPermissions);
  const AdminProfileHDC = Layout(AdminProfile);
  const AdminExamsHDC = Layout(AdminExams);
  const AdminPublicExamsHDC = Layout(AdminPublicExams);
  const AdminPublicExamReportsHDC = Layout(AdminPublicExamReports);
  const AdminAddExamQuestionHDC = Layout(AdminAddExamQuestion);
  const AdminEditExamQuestionHDC = Layout(AdminEditExamQuestion);
  const AdminReportsHDC = Layout(AdminReports);
  const AdminShareReportHDC = Layout(AdminShareReport);
  const AdminViewUserReportsHDC = Layout(AdminViewUserReports);
  const AdminShareReportPreviewHDC = Layout(AdminShareReportPreview);
  const AdminStudentShareReportAllHDC = Layout(AdminStudentShareReportAll);
  const AdminStudentShareGroupReportHDC = Layout(AdminStudentShareGroupReport);
  const AdminStudentGroupReportSharePreviewHDC = Layout(
    AdminStudentGroupReportSharePreview
  );
  const AdminEvaluationHDC = Layout(AdminEvaluation);
  const AdminEvaluationStudentsListHDC = Layout(AdminEvaluationStudentsList);
  const AdminEvaluationDetailsHDC = Layout(AdminEvaluationDetails);
  const AdminEditEvaluationDetailsHDC = Layout(AdminEditEvaluationDetails);
  const AdminSubjectiveReportsHDC = Layout(AdminSubjectiveReports);
  const AdminSubjectiveTopRankHDC = Layout(AdminSubjectiveTopRank);
  const AdminSubjectiveShareReportHDC = Layout(AdminSubjectiveShareReport);
  const AdminSubjectiveShareReportPreviewHDC = Layout(
    AdminSubjectiveShareReportPreview
  );
  const AdminTopRankHDC = Layout(AdminTopRank);
  const AdminContactHDC = Layout(AdminContact);
  const AdminCreateVendorHDC = Layout(AdminCreateVendor);
  const AdminEditorVendorHDC = Layout(AdminEditorVendor);
  const AdminCategoryPermissionsHDC = Layout(AdminCategoryPermissions);
  const AdminFilesHDC = Layout(AdminFiles);
  const AdminVideosHDC = Layout(AdminVideos);
  const AdminVideoExamHDC = Layout(AdminVideoExam);
  const AdminVideoExamListHDC = Layout(AdminVideoExamList);
  const AdminEditVideoExamHDC = Layout(AdminEditVideoExam)
  const AdminVideoExamReportHDC = Layout(AdminVideoExamReport)
  const AdminStudentQuestionHDC = Layout(AdminStudentQuestion);
  const AdminEditStudentQuestionHDC = Layout(AdminEditStudentQuestion)
  const AdminInstituteInfoHDC = Layout(AdminInstituteInfo);
  const AdminAddTeachersHDC = Layout(AdminAddTeachers);
  const AdminJobNotificationHDC = Layout(AdminJobNotification);
  const AdminFacilitiesHDC = Layout(AdminFacilities);
  const AdminInstituteEnquiryHDC = Layout(AdminInstituteEnquiry);
  const AdminSubjectiveExamHDC = Layout(AdminSubjectiveExam);
  const AdminAddSubjectiveExamQuestionHDC = Layout(
    AdminAddSubjectiveExamQuestion
  );
  const AdminAllInstituteStudentHDC = Layout(AdminAllInstituteStudent);
  const AdminEditSubjectiveExamHDC = Layout(AdminEditSubjectiveExam);
  const AdminPlansHDC = Layout(AdminPlans);
  const AdminAssignVendorPlanListHDC = Layout(AdminAssignVendorPlanList);
  const AdminNewBatchHDC = Layout(AdminNewBatch);
  const AdminNewCourseRequestHDC = Layout(AdminNewCourseRequest);
  const AdminStudentBatchHDC = Layout(AdminStudentBatch);
  const AdminAddCoursesHDC = Layout(AdminAddCourses);
  const AdminNewCourseRequestTableAddBatchHDC = Layout(
    AdminNewCourseRequestTableAddBatch
  );
  const AdminPublicUserHDC = Layout(AdminPublicUser);
  const AdminPublicUserTableAddBatchHDC = Layout(AdminPublicUserTableAddBatch);
  const AdminContactSupportHDC = Layout(AdminContactSupport);
  const AdminContactSupportListHDC = Layout(AdminContactSupportList)

  // Sub-Admin & Other Roles
  const AdminSubEditorVendorHDC = Layout(AdminSubEditorVendor);
  const AdminSubCategoryPermissionsHDC = Layout(AdminSubCategoryPermissions);
  const AdminSubRoutesPermissionsHDC = Layout(AdminSubRoutesPermissions);

  return (
    <>
      <ConnectionStatus />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ProSidebarProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* <Route path="/testseries/home" element={ <DemoHome />} /> */}
            <Route path="/book-a-demo" element={<BookADemo />} />
            <Route path="/testseries/about" element={<DemoAbout />} />
            <Route path="/testseries/contact" element={<DemoContact />} />
            <Route path="/testseries/plan" element={<DemoPlan />} />
            <Route
              path="/testseries/institute/:formattedInstituteName"
              element={<InstitutesInformation />}
            />
            <Route
              path="/testseries/privacy-policy"
              element={<DemoPrivacyPolicy />}
            />
            <Route
              path="/testseries/terms-service"
              element={<DemoTermsOfService />}
            />
            <Route path="/testseries/refund-policy" 
            element={<DemoRefundPolicy />}
            />
            {/* PhonePe Payment  */}
            <Route path="/payment" element={<PhonePe />} />
            <Route path="/payment-success/:orderId" element={<PaymentSuccess />} />
            <Route path="/payment-failure/:orderId" element={<PaymentFailure />} />
            <Route path="/payment-pending/:orderId" element={<PaymentPending />} />
            
            {/* Common Routes  */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/reset/:token" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-service" element={<TermsOfService />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route
              path="/access-public-user"
              element={
                localStorage.getItem("hasRegistered") ? (
                  <Navigate
                    to="/access-public-exam/:examId/:instituteId"
                    replace
                  />
                ) : (
                  <AccessPublicUser />
                )
              }
            />
            <Route
              path="/access-public-exam/:examId/:instituteId"
              element={
                accessPublicUserData ? (
                  <AccessPublicExam />
                ) : (
                  <AccessPublicUser />
                )
              }
            />
            <Route
              path="/write-access-exam/:examId/:instituteId"
              element={
                accessPublicUserData ? (
                  <WriteAccessPublicExam />
                ) : (
                  <AccessPublicUser />
                )
              }
            />
            {/* Authenticated Routes */}
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  // user && user.role === 0 ? (
                  instituteUser ? (
                    <Home />
                  ) : user && user?.role >= 1 ? (
                    <AdminDashboardHDC />
                  ) : (
                    <DemoHome />
                  )
                ) : (
                  <DemoHome />
                )
              }
            />
            <Route
              path="/student/dashboard"
              element={
                isAuthenticated ? (
                  user &&
                  user.role === 0 &&
                  user.studentCourse &&
                  user.studentCourse[0]?.instituteId ? (
                    <StudentDashboard />
                  ) : (
                    <DemoHome />
                  )
                ) : (
                  <DemoHome />
                )
              }
            />
            <Route
              path="/profile"
              element={isAuthenticated ? <Profile /> : <Login />}
            />
            <Route
              path="/write-exam/:id"
              element={
                isAuthenticated && instituteUser ? <WriteExam /> : <Login />
              }
            />
            <Route
              path="/about"
              element={isAuthenticated && instituteUser ? <About /> : <Login />}
            />
            <Route
              path="/exams"
              element={isAuthenticated && instituteUser ? <Exam /> : <Login />}
            />
            <Route
              path="/subjective/exams"
              element={
                isAuthenticated && instituteUser ? (
                  <SubjectiveExam />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/subjective/write-exam/:id"
              element={
                isAuthenticated && instituteUser ? (
                  <SubjectiveWriteExam />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/notes"
              element={isAuthenticated && instituteUser ? <Notes /> : <Login />}
            />
            <Route
              path="/courses"
              element={
                isAuthenticated && instituteUser ? <Videos /> : <Login />
              }
            />
              <Route
              path="/video/course"
              element={
                isAuthenticated && instituteUser ? <VideoExam /> : <Login />
              }
            />
            <Route
              path="/video/:id"
              element={
                isAuthenticated && instituteUser ? <StartVideo /> : <Login />
              }
            />
             <Route
              path="/video/exam/:videoId"
              element={
                isAuthenticated && instituteUser ? <StartVideoExam /> : <Login />
              }
            />
            <Route
              path="/contact"
              element={
                isAuthenticated && instituteUser ? <Contact /> : <Login />
              }
            />
            <Route
              path="/reports"
              element={
                isAuthenticated && instituteUser ? <Reports /> : <Login />
              }
            />
            <Route
              path="/reports/top-rank"
              element={
                isAuthenticated && instituteUser ? <TopRank /> : <Login />
              }
            />
            {/* Admin Routes */}
            {user?.role >= 1 ? (
              <>
                <Route
                  path="/admin/dashboard"
                  element={<AdminDashboardHDC />}
                />
                <Route path="/admin/transactions" element={<AdminTransactionsHDC />} />
                <Route path="/admin/payment/details" element={<AdminPaymentDetailsHDC />} />
                <Route path="/admin/roles" element={<AdminRolesHDC />} />
                <Route
                  path="/admin/permissions"
                  element={<AdminPermissionsHDC />}
                />
                <Route
                  path="/admin/category/permissions"
                  element={<AdminCategoryPermissionsHDC />}
                />
                <Route
                  path="/admin/create/vendor"
                  element={<AdminCreateVendorHDC />}
                />
                <Route
                  path="/admin/create/editor/vendor"
                  element={<AdminEditorVendorHDC />}
                />
                <Route path="/admin/profile" element={<AdminProfileHDC />} />
                <Route path="/admin/exams" element={<AdminExamsHDC />} />
                <Route
                  path="/admin/public/exams"
                  element={<AdminPublicExamsHDC />}
                />
                <Route
                  path="/admin/public/exam/reports"
                  element={<AdminPublicExamReportsHDC />}
                />
                <Route
                  path="/admin/add/exam"
                  element={<AdminAddExamQuestionHDC />}
                />
                <Route
                  path="/admin/edit/exam/:id"
                  element={<AdminEditExamQuestionHDC />}
                />
                <Route
                  path="/admin/subjective/exams"
                  element={<AdminSubjectiveExamHDC />}
                />
                <Route
                  path="/admin/add/subjective/exam"
                  element={<AdminAddSubjectiveExamQuestionHDC />}
                />
                <Route
                  path="/admin/edit/subjective/exam/:id"
                  element={<AdminEditSubjectiveExamHDC />}
                />
                <Route path="/admin/files" element={<AdminFilesHDC />} />
                <Route path="/admin/courses" element={<AdminVideosHDC />} />
                <Route path="/admin/video/exam" element={<AdminVideoExamHDC />} />
                <Route path= "/admin/video/exam/list" element={<AdminVideoExamListHDC />} />
                <Route path= "/admin/edit/video/exam/:id" element={<AdminEditVideoExamHDC/>} />
                <Route path= "/admin/video/exam/report" element={<AdminVideoExamReportHDC />} />
                <Route path= "/admin/student/question/video/exam" element={<AdminStudentQuestionHDC />}/>
                <Route path= "/admin/edit/student/question/:id" element={<AdminEditStudentQuestionHDC/>} />
                <Route path="/admin/reports" element={<AdminReportsHDC />} />
                <Route
                  path="/admin/share/report"
                  element={<AdminShareReportHDC />}
                />
                <Route
                  path="/admin/student/share/all/report"
                  element={<AdminStudentShareReportAllHDC />}
                />
                <Route
                  path="/admin/reports/view/:userEmail"
                  element={<AdminViewUserReportsHDC />}
                />
                <Route
                  path="/admin/share/report/preview"
                  element={<AdminShareReportPreviewHDC />}
                />
                <Route
                  path="/admin/student/share/group/report"
                  element={<AdminStudentShareGroupReportHDC />}
                />
                <Route
                  path="/admin/student/share/group/report/preview"
                  element={<AdminStudentGroupReportSharePreviewHDC />}
                />
                <Route
                  path="/admin/subjective/evaluation"
                  element={<AdminEvaluationHDC />}
                />
                <Route
                  path="/admin/subjective/students-details/:id"
                  element={<AdminEvaluationStudentsListHDC />}
                />
                <Route
                  path="/admin/subjective/exam-details/:id"
                  element={<AdminEvaluationDetailsHDC />}
                />
                <Route
                  path="/admin/subjective/edit-exam-details/:id"
                  element={<AdminEditEvaluationDetailsHDC />}
                />
                <Route
                  path="/admin/subjective/top/rank"
                  element={<AdminSubjectiveTopRankHDC />}
                />
                <Route
                  path="/admin/subjective/reports"
                  element={<AdminSubjectiveReportsHDC />}
                />
                <Route path="/admin/top/rank" element={<AdminTopRankHDC />} />
                <Route
                  path="/admin/share/subjective/report"
                  element={<AdminSubjectiveShareReportHDC />}
                />
                <Route
                  path="/admin/share/subjective/report/preview"
                  element={<AdminSubjectiveShareReportPreviewHDC />}
                />
                <Route path="/admin/contact" element={<AdminContactHDC />} />
                {/* Sub-Admin & Other Roles */}
                <Route
                  path="/admin/create/editor"
                  element={<AdminSubEditorVendorHDC />}
                />
                <Route
                  path="/admin/permission/category"
                  element={<AdminSubCategoryPermissionsHDC />}
                />
                <Route
                  path="/admin/permission/routes"
                  element={<AdminSubRoutesPermissionsHDC />}
                />
                <Route
                  path="/admin/institute/info"
                  element={<AdminInstituteInfoHDC />}
                />
                <Route
                  path="/admin/add/teachers"
                  element={<AdminAddTeachersHDC />}
                />
                <Route
                  path="/admin/job/notification"
                  element={<AdminJobNotificationHDC />}
                />
                <Route
                  path="/admin/institute/facilities"
                  element={<AdminFacilitiesHDC />}
                />
                <Route
                  path="/admin/institute/enquiry"
                  element={<AdminInstituteEnquiryHDC />}
                />
                <Route
                  path="/admin/institute/all/student"
                  element={<AdminAllInstituteStudentHDC />}
                />
                <Route path="/admin/vendor/plans" element={<AdminPlansHDC />} />
                <Route
                  path="/admin/assign/vendor/plans"
                  element={<AdminAssignVendorPlanListHDC />}
                />
                <Route path="/admin/batch" element={<AdminNewBatchHDC />} />

                <Route
                  path="/admin/student/batch"
                  element={
                    isAuthenticated ? <AdminStudentBatchHDC /> : <Login />
                  }
                />
                <Route
                  path="/admin/institute/course"
                  element={isAuthenticated ? <AdminAddCoursesHDC /> : <Login />}
                />
                <Route
                  path="/admin/new/course/request"
                  element={<AdminNewCourseRequestHDC />}
                />
                <Route
                  path="/admin/add/new/course/request/student"
                  element={
                    isAuthenticated ? (
                      <AdminNewCourseRequestTableAddBatchHDC />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/admin/public/student"
                  element={isAuthenticated ? <AdminPublicUserHDC /> : <Login />}
                />
                <Route
                  path="/admin/add/public/student"
                  element={
                    isAuthenticated ? (
                      <AdminPublicUserTableAddBatchHDC />
                    ) : (
                      <Login />
                    )
                  }
                />
                 <Route
                  path="/admin/contact/support"
                  element={isAuthenticated ? <AdminContactSupportHDC /> : <Login />}
                />
                 <Route
                  path="/admin/contact/support/list"
                  element={isAuthenticated ? <AdminContactSupportListHDC /> : <Login />}
                />
              </>
            ) : null}
            {/* Page not found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ProSidebarProvider>
    </>
  );
}

export default App;
