import React from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import { Box, Typography, Paper, Divider } from "@mui/material";
import Footer from "../Components/Footer";

const RefundPolicy = () => {
  return (
    <>
      <Navbar />
      <PageTitle title="Refund Policy | Test Series" />
      <Box
        sx={{
          mt: { xs: 16, sm: 16, md: 16, lg: 18 },
          mb: 8,
          mx: { md: 12, xs: 2 },
          backgroundColor: "#f9f9f9",
        }}
      >
        <Paper
          elevation={3}
          sx={{ p: 4, borderRadius: "15px", backgroundColor: "#ffffff" }}
        >
          <Typography
            variant="h3"
            align="center"
            // className="courses_title"
            gutterBottom
            sx={{
              letterSpacing: "3px",
              lineHeight: 1.8,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#2C3E50",
            }}
          >
            Refund Policy
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Refund
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 2,
              mb: 5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            "We are not providing any kind of Refund"
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Contact Us
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 2,
              mb: 5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            If you have any questions or concerns regarding this Refund Policy,
            please contact us at:
            <br />
            E-mail: testseries@gatiktech.com
            <br />
            Contact Number: +91-7891555777
          </Typography>

          <Divider sx={{ mb: 3 }} />
        </Paper>
      </Box>
      <Footer />
    </>
  );
};

export default RefundPolicy;
