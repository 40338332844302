import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';

const HeaderTop = () => {
  const { collapseSidebar } = useProSidebar();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ boxShadow: 0, bgcolor: "#fafafa" }}>
        <Toolbar>
          <IconButton
            onClick={() => collapseSidebar()}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, color: "#1976d2" }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" alignItems="center">
            <Link
              to="/admin/contact/support"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              Contact Support <ContactSupportOutlinedIcon style={{ marginLeft: 8 }} />
            </Link>
          </Box>
          {/* <Box
            sx={{
              ml: "auto",
              p: 1,
              color: "#cc00cc",
              background: "#FFF",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.1);",
            }}
          >
            <b
              style={{
                fontSize: "18px",
                padding: "5px",
                margin: "5px",
                color: "rgb(25, 118, 210)",
              }}
            >
              Test Series
            </b>{" "}
            <b
              style={{
                border: "1px solid #003d99",
                padding: "2px",
                color: "#80b3ff",
              }}
            >
              Beta
            </b>
          </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HeaderTop;
