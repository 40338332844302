import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { message, Modal } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import axios from "../../../axiosInstance";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import EditAssignVendorPlan from "./EditAssignVendorPlan";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AssignVendorPlanList() {
  const [value, setValue] = useState(0);
  const [plans, setPlans] = useState([]);
  const [response, setResponse] = useState(null);
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [vendors, setVendors] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [vendorPlans, setVendorPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPlans, setFilteredPlans] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const planStartDate = moment(startDate).format("MM/DD/YYYY");
  const planEndDate = moment(endDate).format("MM/DD/YYYY");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Plan Data
  const getAllPlan = async () => {
    try {
      const response = await axios.get(
        `/api/v1/plan/admin-get-assign-plan-to-all-user`
      );
      if (response?.data?.success === true) {
        setPlans(response?.data?.userPlan);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPlan();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Assign Plan
  const { confirm } = Modal;
  const deleteAssignPlan = async (e, userId) => {
    confirm({
      title: "Are you sure you want to delete this Assign Plan?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/plan/admin-delete-assign-plan-to-user",
            {
              data: { userId },
            }
          );
          if (data.success === true) {
            message.success(data?.message);
            getAllPlan();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete the assign plan"
          );
        }
      },
      onCancel() {
        console.log("Delete operation canceled");
      },
    });
  };

  // Function to toggle vendor's active status
  const toggleVendorActiveStatus = async (
    userId,
    startDate,
    endDate,
    isActive
  ) => {
    const action = isActive ? "activate" : "deactivate";
    confirm({
      title: `Are you sure you want to ${action} this vendor's plan?`,
      content: `Please confirm if you want to ${action} this vendor's plan with the provided start and end dates.`,
      okText: "Yes, Confirm",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.post(
            "/api/v1/plan/admin-deactivate-assign-plan-to-user",
            {
              userId,
              startDate,
              endDate,
              isActive,
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            await getAllPlan();
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong."
          );
        }
      },
      onCancel() {
        console.log("Vendor activation/deactivation canceled");
      },
    });
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 90,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteAssignPlan(e, value?.row?.userId?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditAssignVendorPlan
            refreshData={getAllPlan}
            userId={value?.row?.userId}
            planId={value?.row?.planId}
            startDate={value?.row?.startDate}
            endDate={value?.row?.endDate}
            active={value?.row?.active}
          />
        </Box>
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 150,
      renderCell: (params) => {
        const userId = params?.row?.userId?._id;
        const isActive = params?.row?.isActive;
        const startDate = params?.row?.startDate;
        const endDate = params?.row?.endDate;

        return (
          <select
            value={isActive ? "true" : "false"}
            onChange={(e) =>
              toggleVendorActiveStatus(
                userId,
                startDate,
                endDate,
                e.target.value === "true"
              )
            }
            style={{
              padding: "6px 12px",
              fontSize: "14px",
              borderRadius: "8px",
              border: `1px solid ${isActive ? "green" : "red"}`,
              backgroundColor: isActive ? "#e0ffe0" : "#ffe0e0",
              color: isActive ? "green" : "red",
              cursor: "pointer",
              outline: "none",
              transition:
                "border-color 0.2s, background-color 0.2s, color 0.2s",
            }}
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        );
      },
    },
    {
      field: "name",
      headerName: "Plan Name",
      width: 150,
      renderCell: (row) => row?.row?.planId?.name,
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 150,
      renderCell: (row) => row?.row?.userId?.instituteId,
    },
    {
      field: "vendor name",
      headerName: "Name",
      width: 150,
      renderCell: (row) => row?.row?.userId?.name,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderCell: (row) => row?.row?.userId?.email,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderCell: (row) => row?.row?.userId?.phone,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) =>
        moment(params?.row?.startDate).format("MM/DD/YYYY"),
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 140,
      renderCell: (params) => moment(params?.row?.endDate).format("MM/DD/YYYY"),
    },
  ];

  // get-all-vendor
  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    displayVendor();
  }, []);

  // Get All Vendor Plan
  const getAllVendorPlan = async () => {
    try {
      const response = await axios.get(`/api/v1/plan/admin-get-plan`);
      if (response?.data?.success === true) {
        setVendorPlans(response?.data?.plan);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to fetch plans");
    }
  };
  useEffect(() => {
    getAllVendorPlan();
  }, []);

  const handleChangeVendorPlan = (event) => {
    setSelectedPlanId(event.target.value);
  };

  // Assign A New Vendor Plans
  const handleSubmit = async (e) => {
    e.preventDefault();
    const AssignPlanData = {
      startDate: planStartDate,
      endDate: planEndDate,
      userId: vendorId,
      planId: selectedPlanId,
    };
    try {
      const { data } = await axios.post(
        "/api/v1/plan/admin-assign-plan-to-user",
        AssignPlanData
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setVendorName("");
        setVendorEmail(null);
        setVendorPhone("");
        setStartDate(null);
        setEndDate(null);
        setSelectedPlanId(null);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  const handlerVendorEmail = (e) => {
    const selectedValue = e.target.value;
    setVendorName(selectedValue?.name);
    setVendorId(selectedValue?._id);
    setVendorPhone(selectedValue?.phone);
  };
  const filteredTableData = plans.map((plan, index) => ({
    ...plan,
    rowNumber: index + 1,
  }));

  // Search Functionality
  const handleSearch = (event) => {
    setSearchTerm(event?.target?.value);
    const searchTermLower = event?.target?.value.toLowerCase();
    const filtered = plans?.filter(
      (plan) =>
        plan?.userId?.email.toLowerCase().includes(searchTermLower) ||
        plan?.planId?.name.toLowerCase().includes(searchTermLower) ||
        plan?.userId?.name.toLowerCase().includes(searchTermLower)
    );
    const filteredTableData = plans.map((plan, index) => ({
      ...plan,
      rowNumber: index + 1,
    }));
    setFilteredPlans(filtered);
  };

  return (
    <>
      <PageTitle title="Admin Vendor Plans | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Assign All Vendor Plans
        </Typography>

        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="#"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Super Admin
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            {" "}
            Assign Vendor Plans
          </Typography>
        </Breadcrumbs>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Assign Plans List" {...TabPanel(0)} />
            <Tab label="Assign Plans" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Plan List */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Assign Vendor Plan List
          </Typography>
          <Box sx={{ mb: 4, mt: 2 }}>
            <form>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search by Email or Plan Name"
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchIcon sx={{ color: "#1976d2" }} />
                    </IconButton>
                  ),
                }}
                sx={{
                  bgcolor: "white",
                  borderRadius: "20px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </form>
          </Box>
          <Paper
            sx={{
              bgcolor: "white",
              borderRadius: 3,
              overflow: "hidden",
            }}
          >
            <Box sx={{ height: 450, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={searchTerm ? filteredPlans : filteredTableData} 
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/* Add Plans */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Assign a new Vendor Plan
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <Box sx={{ minWidth: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Vendors Email
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Vendors Email"
                  onChange={handlerVendorEmail}
                  sx={{ minWidth: "50%" }}
                  required
                >
                  {vendors && vendors.length > 0 ? (
                    vendors.map((vendor, index) => (
                      <MenuItem key={index} value={vendor}>
                        {vendor.email}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      Assign a new Vendor Plan
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Vendor Name "
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Name"
              value={vendorName}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorPhone"
              label="Vendor Phone"
              name="vendorPhone"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Phone"
              value={vendorPhone}
            />
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel shrink id="plan-label">
                Plan Name *
              </InputLabel>
              <Select
                label="plan-label"
                id="plan-select"
                value={selectedPlanId}
                onChange={handleChangeVendorPlan}
                displayEmpty
                sx={{
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
              >
                <MenuItem value="" disabled>
                  Select a Plan
                </MenuItem>
                {vendorPlans.map((plan) => (
                  <MenuItem key={plan._id} value={plan._id}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Assign Plan Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Assign Plan End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Assign New Plan
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
