import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Input,
  MenuItem,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";
import GetVideo from "./GetVideo";
import { getAllCourse } from "../../redux/actions/userAction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Video = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courseData);
  const { user } = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [fileType, setFileType] = useState("video");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentCourse, setStudentCourse] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 500) {
        message.error(
          "File size exceeds the limit. Please upload a file with a maximum size of 500MB."
        );
        return;
      }
      if (!selectedFile.type.startsWith("video/")) {
        message.error("Invalid file type. Please upload a Video.");
        return;
      }
      setVideo(selectedFile);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const videoStartDate = moment(startDate).format("MM/DD/YYYY");
  const videoEndDate = moment(endDate).format("MM/DD/YYYY");

  // Fetch all institute courses
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setStudentCourse(courses);
    }
  }, [courses]);

  // Handle Institute Course
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);

    // Assign a fixed ID for "General Course"
    const generalCourseId = "81af3e57846a42aab058dc4d"; // UUID Generate

    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setStudentCourseId(selectedCourse._id);
    } else if (selectedCourseName === "General") {
      setStudentCourseId(generalCourseId);
    }
  };

  // Add Video and YouTube Url
  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if the file exceeds the max size
    if (video && video.size > MAX_FILE_SIZE) {
      message.error("File size exceeds the 50MB limit.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (video) {
      formData.append("video", video);
    } else {
      formData.append("youtubeUrl", youtubeUrl);
    }
    formData.append("startDate", videoStartDate);
    formData.append("endDate", videoEndDate);
    formData.append("instituteId", user?.instituteId);
    formData.append("vendorId", user?._id);
    formData.append("fileType", fileType);
    formData.append("instituteCourseId", studentCourseId);

    try {
      const { data } = await axios.post(
        "/api/v1/admin/admin-upload-notes-video",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.success === true) {
        message.success(data?.message);
        setTitle("");
        setDescription("");
        setVideo(null);
        setYoutubeUrl("");
        setStartDate(null);
        setEndDate(null);
        setStudentCourse("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data.message || "Failed to upload video.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle title="Admin Courses | Test Series" />
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          All Courses
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Video Courses</Typography>
        </Breadcrumbs>
        
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Video List" {...TabPanel(0)} />
            <Tab label="Add Video" {...TabPanel(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Course Video List
          </Typography>
          <GetVideo />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Upload a Course Video
          </Typography>
          <Container
            component="form"
            className="video_form_style border-style"
            onSubmit={handleSubmit}
            maxWidth="md"
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              select
              name="studentCourse"
              label="Institute Course"
              placeholder="Institute Course"
              value={studentCourse}
              onChange={handleChangeStudentCourse}
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled>Select Course</MenuItem>
              <MenuItem key="generalCourse" value="General">
                General
              </MenuItem>
              {courses &&
                courses.map((course) => (
                  <MenuItem key={course._id} value={course.courseName}>
                    {course.courseName}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="title"
              label="Title"
              name="title"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <h4 style={{ color: "#2C3E50" }}>Short Description</h4>
            <ReactQuill
              value={description}
              onChange={setDescription}
              placeholder="Short Description"
              style={{
                marginBottom: "30px",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "10px",
              }}
            />

            <Box sx={{ mb: 3 }}>
              <label htmlFor="upload-file">
                <Input
                  accept="video/*"
                  id="upload-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Course Video
                </Button>
              </label>
              {video && (
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  {video.name}
                </Typography>
              )}
            </Box>

            {/* Add TextField for YouTube video URL */}
            <TextField
              sx={{
                mb: 3,
                mt: 2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="youtubeUrl"
              label="YouTube Video URL"
              name="youtubeUrl"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Enter YouTube Video URL"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  minDate={startDate || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Add Video"}
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Video;
