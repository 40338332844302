import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  TextField,
  Badge,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import Footer from "../Components/Footer";
import axios from "../axiosInstance";
import useInstituteUser from "../Components/GetInstituteUser";
import StartVideoExam from "./StartVideoExam";
import { useSelector } from "react-redux";

const VideoExam = () => {
  const { user } = useSelector((state) => state.user);
  const studentCourse = useInstituteUser();
  const [videos, setVideos] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const currentDate = moment().startOf("day");
  const navigate = useNavigate();
  const [selectedVideo, setSelectedVideo] = useState(null);

  // Get All video Data
  const getAllVideos = async () => {
    try {
      const response = await axios.post("/api/v1/videos/exam/get-all-video-exam", {
        studentCourse,
      });
      const videoData = response?.data?.data || [];

      const updatedVideoData = videoData.map((video) => {
        const matchedCourse = studentCourse.find(
          (course) => course.studentCourseId === video.instituteCourseId
        );
        return {
          ...video,
          instituteCourseId: matchedCourse?.studentCourseName || video.instituteCourserId,
        };
      });
      setVideos(updatedVideoData);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  // Handle course selection change
  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  // Filter videos based on course and search query
  const getFilteredVideos = () => {
    return videos.filter((video) => {
      const matchesSearch =
        video.questionTopics.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCourse = selectedCourse
        ? video.instituteCourseId === selectedCourse
        : true;

      return matchesSearch && matchesCourse;
    });
  };

  // Search function
  const searchAllVideo = (e) => {
    setSearchQuery(e.target.value);
  };

  // Fetch data on load
  useEffect(() => {
    if (studentCourse) {
      getAllVideos();
    }
  }, [studentCourse]);

  const isYouTubeUrl = (url) => {
    if (!url) return false;
    return /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/.test(url);
  };

  const getYouTubeThumbnailUrl = (url) => {
    if (!isYouTubeUrl(url)) return null;

    const videoIdMatch = url.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    const videoId = videoIdMatch ? videoIdMatch[1] : null;

    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null;
  };

  const handleStartClick = (videoId) => {
    setSelectedVideo(videoId);
  };

  return (
    <div>
      <Navbar />
      <PageTitle title="Courses | Test Series" />
      <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
        <Grid container spacing={3}>
          {/* Search Input */}
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              sx={{
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputLabel-root": {
                  fontWeight: "bold",
                  color: "#1976d2",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}
              fullWidth
              id="text"
              name="text"
              placeholder="Search by Question Topics"
              value={searchQuery}
              onChange={searchAllVideo}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          {/* Course Name Dropdown */}
          <Grid item xs={12} sm={4} md={4}>
            <FormControl
              fullWidth
              sx={{
                mb: 3,
                bgcolor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <InputLabel sx={{ fontWeight: "bold", color: "#1976d2" }}>
                Course Name
              </InputLabel>
              <Select
                value={selectedCourse}
                onChange={handleCourseChange}
                label="Course Name"
                sx={{
                  bgcolor: "white",
                  borderRadius: "8px",
                  "& .MuiSelect-icon": {
                    color: "#1976d2",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                }}
              >
                {/* Add an option for 'All Courses' */}
                <MenuItem value="">
                  <em>All Courses</em>
                </MenuItem>
                {/* Check if courses are available, then render the items */}
                {studentCourse?.length > 0 ? (
                  studentCourse.map((course) => (
                    <MenuItem
                      key={course.studentCourseId}
                      value={course.studentCourseName}
                    >
                      {course.studentCourseName}
                    </MenuItem>
                  ))
                ) : (
                  // Fallback if there are no courses
                  <MenuItem disabled>No courses available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>

      {selectedVideo ? (
        <StartVideoExam videoId={selectedVideo} userId={user?._id} />
      ) : (
        <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
          <Box
            sx={{
              pt: 5,
              pb: 5,
              minHeight: "83vh",
              mr: { md: 12, xs: 2 },
              ml: { md: 12, xs: 2 },
            }}
          >
            <Grid container spacing={8}>
              {getFilteredVideos().length > 0 ? (
                getFilteredVideos().map((video, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Badge
                      badgeContent="Upcoming"
                      color="primary"
                      invisible={moment(video.startDate).isSameOrBefore(currentDate)}
                    >
                      <Card
                        sx={{
                          transition: "0.3s !important",
                          "&:hover": { transform: "scale(1.05)" },
                          borderRadius: "15px",
                          boxShadow:
                            "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                          height: { xs: 400, sm: 400, md: 450 },
                          width: "100% !important",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardMedia
                          component="div"
                          title="Video"
                          sx={{
                            height: { xs: 160, sm: 200, md: 240 },
                            overflow: "hidden",
                          }}
                        >
                          {isYouTubeUrl(video.videoUrl) ? (
                            <img
                              src={getYouTubeThumbnailUrl(video.videoUrl)}
                              alt="YouTube Thumbnail"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <video
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            >
                              <source src={video.videoUrl} />
                            </video>
                          )}
                        </CardMedia>
                        <CardContent
                          sx={{ flexGrow: 1, padding: { xs: 1, sm: 2 } }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="courses_desc"
                            sx={{
                              mt: 1,
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              overflow: "hidden",
                              fontSize: "15px",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: video.questionTopics,
                            }}
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              mt: 1,
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#34495E",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <strong>
                              {moment(video.startDate).isAfter(currentDate)
                                ? "Start Date:"
                                : "End Date:"}
                            </strong>{" "}
                            {moment(video.startDate).isAfter(currentDate)
                              ? new Date(video?.startDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, "-")
                              : new Date(video?.endDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, "-")}
                          </Typography>
                        </CardContent>
                        <CardActions
                          sx={{
                            mt: "auto",
                            padding: { xs: 1, sm: 2 },
                            width: "100%",
                          }}
                        >
                          {moment(video.startDate).isSameOrBefore(currentDate) ? (
                            <Button
                              onClick={() => navigate(`/video/exam/${video._id}`)}
                              variant="outlined"
                              color="primary"
                              fullWidth
                              sx={{
                                mt: 1,
                                mb: 2,
                                borderRadius: "50px",
                                textTransform: "none",
                                fontFamily: "Poppins, sans-serif",
                                letterSpacing: ".1rem",
                              }}
                            >
                              Start Watching
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="primary"
                              fullWidth
                              disabled
                              sx={{
                                mt: 1,
                                mb: 2,
                                borderRadius: "50px",
                                textTransform: "none",
                                fontFamily: "Poppins, sans-serif",
                                letterSpacing: ".1rem",
                              }}
                            >
                              Upcoming Courses
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Badge>
                  </Grid>
                ))
              ) : (
                <Box
                  style={{
                    color: "#878787",
                    margin: "70px 80px",
                    fontSize: 18,
                  }}
                >
                  No Available Video Exams. Please Contact Your Institute!!
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      )}

      <Footer />
    </div>
  );
};

export default VideoExam;
