import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  IconButton,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";

const VideoExamList = () => {
  const { user } = useSelector((state) => state.user);
  const [videoExams, setVideoExams] = useState([]);
  const [filteredVideoExams, setFilteredVideoExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get all video exams
  const getAllVideoExams = async () => {
    try {
      const response = await axios.get(
        `/api/v1/videos/exam/admin-get-all-videoExam`
      );
      if (response?.data?.success === true) {
        setVideoExams(response?.data?.data);
        setFilteredVideoExams(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllVideoExams();
  }, []);

  // Filter video exams based on search query
  useEffect(() => {
    let filtered = videoExams;

    if (searchQuery) {
      filtered = filtered.filter((exam) =>
        exam.questionTopics.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredVideoExams(filtered);
  }, [searchQuery, videoExams]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Delete Exam
  const { confirm } = Modal;
  const deleteVideoExam = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Video Exam?",
      content:
        "This action will permanently delete the video exam. Are you sure you want to continue?",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/videos/exam/admin-delete-by-id-videoExam",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getAllVideoExams();
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete video exam."
          );
        }
      },
      onCancel() {
        console.log("Delete action canceled.");
      },
    });
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteVideoExam(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <Link to={`/admin/edit/video/exam/${value?.row?._id}`}>
            <IconButton aria-label="edit">
              <EditIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Link>
        </Box>
      ),
    },

    {
      field: "questionTopics",
      headerName: "Question Topics",
      width: 290,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textAlign: "center",
            marginTop: "10px",
          }}
          dangerouslySetInnerHTML={{ __html: params.row.questionTopics }}
        />
      ),
    },
    {
      field: "videoUrl",
      headerName: "Video Url",
      width: 190,
      editable: true,
      renderCell: (params) => {
        const url = params.value;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Button
              variant="outlined"
              color="primary"
              className="courses_desc"
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              {" "}
              Watch Video{" "}
            </Button>
          </a>
        );
      },
    },

    {
      field: "questions",
      headerName: "Total Questions",
      width: 180,
      editable: true,
      renderCell: (row) => row?.row?.questions?.length,
    },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 140,
      editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 140,
      editable: true,
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 140,
      editable: true,
    });
  }

  return (
    <>
      <PageTitle title="Admin Video Exam | Test Series" />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        All Video Exam And Question
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/video/exam"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Video Exam
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Video Exam List</Typography>
      </Breadcrumbs>

      <Box
        style={{
          width: "auto",
          overflowY: "hidden",
          overflowX: "scroll",
        }}
      >
        <Box
          sx={{
            pb: 2,
            display: "flex",
            width: "auto",
            justifyContent: "left",
          }}
        >
          <Link style={{ textDecoration: "none" }} to="/admin/video/exam">
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              className="courses_desc"
              sx={{
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
                color: "white",
              }}
            >
              Create Video Exam
            </Button>
          </Link>
        </Box>

        {/* Search Input */}
        <Box sx={{ pb: 2, mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Question Topics"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
        </Box>

        <Paper sx={{ bgcolor: "white" }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              rows={filteredVideoExams.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default VideoExamList;
