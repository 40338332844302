import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Popover,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { message, Modal } from "antd";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { jsPDF } from "jspdf";
import DownloadIcon from "@mui/icons-material/Download"; 
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { useSelector } from "react-redux";

const AllInstituteStudent = () => {
  const { user } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allStudents, setAllStudents] = useState([]);

  // Get All Student List
  const getAllStudentList = async () => {
    try {
      const response = await axios.get(
        `/api/v1/batch/admin-get-institute-all-user-batch`
      );
      if (response?.data?.success === true) {
        setUsers(response?.data?.user);
        setAllStudents(response.data.user);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllStudentList();
  }, []);

  // Filter users based on search term
  const filteredUsers = users.filter((user) => {
    const courseNames = user?.studentCourse
      .map((course) => course.studentCourseName)
      .join(", ");
    return (
      user.studentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.studentEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
      courseNames.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // delete User
  const { confirm } = Modal;
  const deleteUser = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Student?",
      content: "This action cannot be undone. Please confirm.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/batch/admin-delete-institute-user-all-info-batch",
            {
              data: { id },
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            getAllStudentList();
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      },
      onCancel() {
        console.log("Delete operation canceled");
      },
    });
  };

  // Generate PDF
  const generatePDF = (user) => {
    Modal.confirm({
      title: "Confirm Download",
      content:
        "Would you like to download the details of the student as a PDF file?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        const doc = new jsPDF();

        // Set the title
        doc.setFontSize(16);
        doc.setTextColor(0, 51, 102);
        doc.text("Student Details", 105, 10, { align: "center" });

        // Draw a line below the title
        doc.setDrawColor(0, 51, 102);
        doc.line(10, 15, 200, 15);

        // Set basic information
        doc.setFont("Times", "normal");
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(`Student Name: `, 10, 30);
        doc.setTextColor(34, 34, 34);
        doc.text(`${user.studentName}`, 50, 30);

        doc.setTextColor(0, 0, 0);
        doc.text(`Student Email: `, 10, 40);
        doc.setTextColor(34, 34, 34);
        doc.text(`${user.studentEmail}`, 50, 40);

        doc.setTextColor(0, 0, 0);
        doc.text(`Student Phone: `, 10, 50);
        doc.setTextColor(34, 34, 34);
        doc.text(`${user.studentPhone}`, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.text(`Parent Name: `, 10, 60);
        doc.setTextColor(34, 34, 34);
        doc.text(`${user.parentName || "N/A"}`, 50, 60);

        doc.setTextColor(0, 0, 0);
        doc.text(`Parent Email: `, 10, 70);
        doc.setTextColor(34, 34, 34);
        doc.text(`${user.parentEmail || "N/A"}`, 50, 70);

        doc.setTextColor(0, 0, 0);
        doc.text(`Parent Phone: `, 10, 80);
        doc.setTextColor(34, 34, 34);
        doc.text(`${user.parentPhone || "N/A"}`, 50, 80);

        doc.setTextColor(0, 0, 0);
        doc.text(`Joined On: `, 10, 90);
        doc.setTextColor(34, 34, 34);
        doc.text(
          `${new Date(user.createdAt)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
            .replace(/ /g, "-")}`,
          50,
          90
        );

        // Draw another line to separate sections
        doc.setDrawColor(0, 51, 102);
        doc.line(10, 100, 200, 100);

        // Course details
        doc.setFontSize(14);
        doc.setTextColor(0, 51, 102);
        doc.text("Courses Details", 105, 110, { align: "center" });

        const courses = user.studentCourse;
        let startY = 120;
        let totalPendingFees = 0;

        courses.forEach((course, index) => {
          doc.setFontSize(12);
          doc.setTextColor(0, 0, 0);

          // Course Name
          doc.text(`Course Name: `, 10, startY);
          doc.setTextColor(34, 34, 34);
          doc.text(`${course.studentCourseName}`, 50, startY);

          // Course Fees
          startY += 10;
          doc.setTextColor(0, 0, 0);
          doc.text(`Course Fees: `, 10, startY);
          doc.setTextColor(34, 34, 34);
          doc.text(`₹ ${course.courseFees}`, 50, startY);
          // Student Paid Fees
          startY += 10;
          doc.setTextColor(0, 0, 0);
          doc.text(`Paid Fees: `, 10, startY);
          doc.setTextColor(34, 34, 34);
          doc.text(`₹ ${course.studentFees}`, 50, startY);
          // Pending Fees
          const pendingFees = course.courseFees - course.studentFees;
          startY += 10;
          doc.setTextColor(0, 0, 0);
          doc.text(`Pending Fees: `, 10, startY);
          doc.setTextColor(255, 0, 0);
          doc.text(`₹ ${pendingFees}`, 50, startY);
          totalPendingFees += pendingFees;
          startY += 15;
        });
        doc.setDrawColor(0, 51, 102);
        doc.line(10, startY, 200, startY);
        startY += 10;
        doc.setFontSize(14);
        doc.setTextColor(0, 51, 102);
        doc.text(`Total Pending Fees: ₹ ${totalPendingFees}`, 10, startY);

        // Save the PDF
        doc.save(`${user.studentName}_details.pdf`);
      },
      onCancel() {
        console.log("Download canceled");
      },
    });
  };

  // Function to generate PDF
  const AllStudentsGeneratePDF = (students) => {
    const doc = new jsPDF();
    Modal.confirm({
      title: "Confirm Download",
      content:
        "Would you like to download the details of the all student as a PDF file?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        const columns = [
          "#",
          "Name",
          "Email",
          "Phone",
          "Course Name",
          "Parent Phone",
          "Create Date",
        ];

        const studentData = students.map((student, index) => {
          const courses =
            student.studentCourse && Array.isArray(student.studentCourse)
              ? student.studentCourse
                  .map((course) => course.studentCourseName)
                  .join(", ")
              : "N/A";

          return [
            index + 1, // Row number
            student.studentName || "N/A",
            student.studentEmail || "N/A",
            student.studentPhone || "N/A",
            courses,
            student.parentPhone || "N/A",
            student.createdAt
              ? moment(student.createdAt).format("MM/DD/YYYY")
              : "N/A",
          ];
        });

        // Add title and table to the PDF
        doc.text("All Student Report", 20, 20);
        doc.autoTable({
          head: [columns],
          body: studentData,
        });

        // Save the generated PDF
        doc.save("All_Students_Details.pdf");
      },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState([]);

  const handleClick = (event, data) => {
    setPopoverData(data);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteUser(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          {/* Add Download Button */}
          <IconButton
            aria-label="download"
            onClick={() => generatePDF(value.row)}
          >
            <DownloadIcon sx={{ color: "green" }} />
          </IconButton>
        </Box>
      ),
    },

    {
      field: "studentName",
      headerName: "Full Name",
      width: 180,
      editable: true,
    },
    {
      field: "studentEmail",
      headerName: "E-mail",
      width: 180,
      editable: true,
    },
    {
      field: "studentPhone",
      headerName: "Student Phone",
      width: 180,
      editable: true,
    },
    {
      field: "studentCourse",
      headerName: "Course Details",
      width: 250,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          className="courses_desc"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "50px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
          onClick={(e) =>
            handleClick(
              e,
              // Filter out courses where the course name is "General"
              params.row.studentCourse
                ?.filter((item) => item.studentCourseName !== "General")
                .map((item) => ({
                 // Conditionally add instituteId only if user.role === 1
              ...(user.role === 1 && { instituteId: item.instituteId || "N/A" }),
                  courseName: item.studentCourseName || "N/A",
                  courseFees: item.courseFees || "N/A",
                  studentFees: item.studentFees || "N/A",
                }))
            )
          }
        >
          Course Details
        </Button>
      ),
    },
    {
      field: "parentPhone",
      headerName: "Parent Phone",
      width: 180,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Personal Details | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Institute All Student Details
        </Typography>

        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 3,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/batch"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Batch
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            Student Details
          </Typography>
        </Breadcrumbs>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Name, Email, or Course"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#1976d2" }} />
              </IconButton>
            ),
          }}
          sx={{
            mb: 3,
            mt: 3,
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => AllStudentsGeneratePDF(allStudents)} // Pass all student data
          startIcon={<DownloadIcon />}
          className="courses_desc"
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Export to PDF
        </Button>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box p={2} sx={{ maxHeight: 300, overflowY: "scroll" }}>
            {" "}
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                fontFamily: "Poppins, sans-serif",
                color: "#2C3E50",
                letterSpacing: "1.5px",
                lineHeight: 1.5,
              }}
              onClick={(e) => handleClick(e, users)}
            >
              Course Details:
            </Typography>
            <Table sx={{ minWidth: 300 }} aria-label="course details table">
              <TableHead
                sx={{
                  backgroundColor: "rgb(25, 118, 210)",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    #
                  </TableCell>
                  {user && user?.role===1 ? (
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "white",
                        fontWeight: "800",
                        fontSize: "15px",
                      }}
                    >
                      Institute ID
                    </TableCell>
                  ) : null}

                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Course Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Course Fees
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Student Fees
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popoverData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    {user && user?.role===1 ? (
                     <TableCell
                     sx={{
                       fontFamily: "Poppins, sans-serif",
                       color: "#555",
                     }}
                   >
                     {data.instituteId}
                   </TableCell>
                  ) : null}
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {data.courseName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {data.courseFees}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {data.studentFees}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Popover>

        <Paper sx={{ bgcolor: "white", mt: 3 }}>
          <Box sx={{ height: 550, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              rows={filteredUsers.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default AllInstituteStudent;
