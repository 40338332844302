import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  TextField,
  IconButton,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Joyride from "react-joyride"; // Import Joyride
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import Icon
import axios from "../../axiosInstance";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import PageTitle from "../../Components/PageTitle/PageTitle";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Transactions = () => {
  const [filter, setFilter] = useState("6 months");
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(6, "month"),
    dayjs(),
  ]);
  const [transactionData, setTransactionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [runTour, setRunTour] = useState(false);

  // Define steps for the guided tour
  const steps = [
    {
      target: ".filter-dropdown",
      content: "Select the time period for your transaction data.",
    },
    {
      target: ".date-range-picker",
      content: "You can also select a custom date range using the calendar.",
    },
    {
      target: ".summary-card",
      content:
        "These cards show the total transaction amount, average transaction amount, and total transactions.",
    },
    {
      target: ".chart-section",
      content:
        "This chart provides a visual representation of the transaction trends over time.",
    },
  ];

  const getDateRange = (filter) => {
    const currentDate = dayjs();
    let startDate, endDate;

    switch (filter) {
      case "6 months":
        startDate = currentDate.subtract(6, "months");
        endDate = currentDate;
        break;
      case "this month":
        startDate = currentDate.startOf("month");
        endDate = currentDate.endOf("month");
        break;
      case "this week":
        startDate = currentDate.startOf("week");
        endDate = currentDate.endOf("week");
        break;
      case "yesterday":
        startDate = currentDate.subtract(1, "day");
        endDate = currentDate.subtract(1, "day");
        break;
      case "today":
        startDate = currentDate.startOf("day");
        endDate = currentDate.endOf("day");
        break;
      case "custom":
        startDate = dateRange[0];
        endDate = dateRange[1];
        break;
      default:
        startDate = currentDate.subtract(6, "months");
        endDate = currentDate;
    }

    return { start: startDate, end: endDate };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/admin-get-transaction");
        const dailyData = response.data.dailyData.map((item) => ({
          day: `${dayjs()
            .year(item._id.year)
            .month(item._id.month - 1)
            .date(item._id.day)
            .format("YYYY-MM-DD")}`,
          totalAmount: item.totalAmount,
          totalTransactions: item.totalTransactions,
        }));
        setTransactionData(dailyData);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const { start, end } = getDateRange(filter);
    const filtered = transactionData.filter((item) =>
      dayjs(item.day).isBetween(start, end, null, "[]")
    );
    setFilteredData(filtered);
  }, [filter, dateRange, transactionData]);

  const totalTransactionAmount = filteredData.reduce(
    (sum, item) => sum + item.totalAmount,
    0
  );
  const averageTransactionAmount =
    filteredData.length > 0 ? totalTransactionAmount / filteredData.length : 0;

  const chartDataAmount = {
    labels: filteredData.map((item) => dayjs(item.day).format("MMM D, YYYY")),
    datasets: [
      {
        label: "Transaction Amount",
        data: filteredData.map((item) => item.totalAmount),
        borderColor: "#1976d2",
        backgroundColor: "rgba(25, 118, 210, 0.2)",
        tension: 0.4,
      },
      {
        label: "Transaction Number",
        data: filteredData.map((item) => item.totalTransactions),
        borderColor: "#4caf50",
        backgroundColor: "rgba(76, 175, 80, 0.2)",
        tension: 0.4,
      },
      {
        label: "Average Transaction Amount",
        data: filteredData.map(() => averageTransactionAmount),
        borderColor: "#f57c00",
        backgroundColor: "rgba(245, 124, 0, 0.2)",
        borderDash: [5, 5],
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Transaction Trends" },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: false,
      },
    },
  };

  return (
    <Box p={3} minHeight={"100px"} pb={5} mb={5}>
      <PageTitle title="Admin Transactions Overview | Test Series" />
      {/* Joyride Tour component */}
      <Joyride
        steps={steps}
        run={runTour}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRunTour(false);
          }
        }}
      />

      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="h6">Overview</Typography>
        </Grid>
        <Grid item>
          <IconButton color="primary" onClick={() => setRunTour(true)}>
            <HelpOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* Filters Section */}
      <Grid container spacing={2} mt={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth className="filter-dropdown">
            <InputLabel id="filter-label">Filter</InputLabel>
            <Select
              labelId="filter-label"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              label="Filter"
            >
              <MenuItem value="6 months">Last 6 Months</MenuItem>
              <MenuItem value="this month">This Month</MenuItem>
              <MenuItem value="this week">This Week</MenuItem>
              <MenuItem value="yesterday">Yesterday</MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              className="date-range-picker"
              startText="Start Date"
              endText="End Date"
              value={dateRange}
              onChange={(newValue) => setDateRange(newValue)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} fullWidth />
                  <TextField {...endProps} fullWidth sx={{ ml: 2 }} />
                </>
              )}
              disabled={filter !== "custom"}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {/* Summary Section */}
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} sm={4}>
          <Paper
            elevation={3}
            sx={{ p: 3, textAlign: "center", bgcolor: "#1976d2" }}
            className="summary-card"
          >
            <Typography variant="h6" color="white">
              Total Transaction Amount
            </Typography>
            <Typography variant="h5" color="white">
              ₹ {totalTransactionAmount.toLocaleString()}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper
            elevation={3}
            sx={{ p: 3, textAlign: "center", bgcolor: "#f57c00" }}
            className="summary-card"
          >
            <Typography variant="h6" color="white">
              Average Transaction Amount
            </Typography>
            <Typography variant="h5" color="white">
              ₹{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(averageTransactionAmount)}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper
            elevation={3}
            sx={{ p: 3, textAlign: "center", bgcolor: "#4caf50" }}
            className="summary-card"
          >
            <Typography variant="h6" color="white">
              Total Transactions
            </Typography>
            <Typography variant="h5" color="white">
              {filteredData.reduce(
                (sum, item) => sum + item.totalTransactions,
                0
              )}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Chart Section */}
      <Box mt={4} height={400} className="chart-section">
        <Typography variant="h6" gutterBottom>
          Transaction Trends
        </Typography>
        <Line data={chartDataAmount} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default Transactions;
