import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  Container,
  Typography,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PlanIcon from "@mui/icons-material/BusinessCenter";
import LoginIcon from "@mui/icons-material/Login";
import PhoneIcon from "@mui/icons-material/Phone";

export default function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElAbout, setAnchorElAbout] = useState(null); // State for About dropdown
  const { isAuthenticated } = useSelector((state) => state.user);
  const [bottomNavValue, setBottomNavValue] = useState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const instituteUser = JSON.parse(localStorage.getItem("instituteUser"));

  // Update bottomNavValue based on current location
  useEffect(() => {
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case "/":
        setBottomNavValue(0);
        break;
      case "/testseries/about":
        setBottomNavValue(1);
        break;
      case "/testseries/plan":
        setBottomNavValue(2);
        break;
      case "/testseries/contact":
        setBottomNavValue(3);
        break;
      case "/login":
        setBottomNavValue(4);
        break;
      default:
        setBottomNavValue(0);
        break;
    }
  }, [window.location.pathname]);

  // Handle bottom navigation change
  const handleBottomNavChange = (event, newValue) => {
    setBottomNavValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/testseries/about");
        break;
      case 2:
        navigate("/testseries/plan");
        break;
      case 3:
        navigate("/testseries/contact");
        break;
      case 4:
        navigate("/login");
        break;
      default:
        break;
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenAboutMenu = (event) => {
    setAnchorElAbout(event.currentTarget);
  };

  const handleCloseAboutMenu = () => {
    setAnchorElAbout(null);
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "#FFF",
          color: "#000",
          fontWeight: "600",
          position: "fixed",
          p: 1.5,
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                wordSpacing: "2px",
                letterSpacing: " 1px",
              }}
            >
              TEST SERIES
            </Typography>

            {/* Menu Icon for Mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      wordSpacing: "2px",
                      letterSpacing: "1px",
                    }}
                    to="/"
                  >
                    Home
                  </Link>
                </MenuItem>
                {/* <MenuItem onClick={handleOpenAboutMenu}>
                  <Typography>About</Typography>
                </MenuItem> */}

<MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      wordSpacing: "2px",
                      letterSpacing: "1px",
                    }}
                    to="/testseries/about"
                  >
                    About
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      wordSpacing: "2px",
                      letterSpacing: "1px",
                    }}
                    to="/testseries/plan"
                  >
                    Plans
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      wordSpacing: "2px",
                      letterSpacing: "1px",
                    }}
                    to="/testseries/contact"
                  >
                    Contact
                  </Link>
                </MenuItem>

                {isAuthenticated ? (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        wordSpacing: "2px",
                        letterSpacing: "1px",
                      }}
                      to="/student/dashboard"
                    >
                      Dashboard
                    </Link>
                  </MenuItem>
                ) : (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        wordSpacing: "2px",
                        letterSpacing: "1px",
                      }}
                      to="/login"
                    >
                      Login
                    </Link>
                  </MenuItem>
                )}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                wordSpacing: "2px",
                letterSpacing: "1px",
              }}
            >
              TEST SERIES
            </Typography>

            {/* Desktop Links */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                }}
                to="/"
              >
                Home
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                }}
                to="/testseries/about"
              >
                About
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                }}
                to="/testseries/plan"
              >
                Plans
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                }}
                to="/testseries/contact"
              >
                Contact
              </Link>

              {isAuthenticated ? (
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    padding: "12px",
                    wordSpacing: "2px",
                    letterSpacing: "1px",
                  }}
                  to="/student/dashboard"
                >
                  Dashboard
                </Link>
              ) : (
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    padding: "12px",
                    wordSpacing: "2px",
                    letterSpacing: "1px",
                  }}
                  to="/login"
                >
                  Login
                </Link>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Bottom Navigation for Mobile */}
      {isMobile && (
        <BottomNavigation
          value={bottomNavValue}
          onChange={handleBottomNavChange}
          showLabels
          sx={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1300 }}
        >
          {/* <BottomNavigationAction label="Home"  />
          <BottomNavigationAction
            label="About"
          />
           <BottomNavigationAction label="Plans" />
          <BottomNavigationAction label="Contact" />
          <BottomNavigationAction label="Login"  /> */}

          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="About" icon={<InfoIcon />} />
          <BottomNavigationAction label="Plans" icon={<PlanIcon />} />
          <BottomNavigationAction label="Contact" icon={<PhoneIcon />} />
          <BottomNavigationAction label="Login" icon={<LoginIcon />} />
        </BottomNavigation>
      )}
    </>
  );
}
