// import React, { useState } from "react";
// import {
//   Grid,
//   Card,
//   CardContent,
//   Typography,
//   Button,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Box,
// } from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";
// import StarIcon from "@mui/icons-material/Star";
// import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// import WhatshotIcon from "@mui/icons-material/Whatshot";
// import DemoNavbar from "./DemoNavbar";
// import { useNavigate } from "react-router-dom";
// import PageTitle from "../PageTitle/PageTitle";
// import DemoFooter from "./DemoFooter";

// const plans = [
//   {
//     Allfeatures: "All features",
//     icon: <StarIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />,
//     title: "Standard",
//     amount: "499",
//     description:
//       "Streamline Essentials; Manage core functionalities, collaboration with parents, and manage student data.",
//     features: [
//       "2 MCQ Exams -> 1 Practice Test , 1 Live Test ",
//       "2 Subjective Exam -> 1 Practice Test , 1 Live Test",
//       "3 PDF Notes Upload",
//       "Video Lecture upload (2 Videos via YT)",
//       "Student Enquiry Notifications",
//       "No Reports",
//       "1 Primary Account",
//       "Student Signup - Unlimited",
//       "Test Series Branding",
//     ],
//   },
//   {
//     Allfeatures: "All features (Pro+)",
//     icon: (
//       <BusinessCenterIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />
//     ),
//     title: "Pro",
//     amount: "799",
//     description:
//       "Empower for Growth; Unlock essential tools for academics and financial management, and more.",
//     features: [
//       "10 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
//       "10 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
//       "15 PDF Notes Upload",
//       "Video Lecture upload (10 Videos via YT)",
//       "Proprietary Video Lectures Upload 4 files (1 gb Total)",
//       "Student Enquiry Notifications",
//       "View Reports",
//       "1 Primary Account 2 Teacher Accounts",
//       "Student Signup - Unlimited",
//       "Test Series Branding",
//     ],
//   },
//   {
//     Allfeatures: "All features (Elite+)",
//     icon: <WhatshotIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />,
//     title: "Elite",
//     amount: "999",
//     description:
//       "Drive Efficiency; Unleash the power of integrations, from devices & institutions, to HRMS, and beyond.",
//     features: [
//       "50 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
//       "50 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
//       "20 PDF Notes Upload",
//       "Video Lecture upload (25 Videos via YT)",
//       "Proprietary Video Lectures Upload 10 files (2 gb Total)",
//       "Student Enquiry Notifications",
//       "Download Reports",
//       "2 Primary Accounts 5 Teacher Accounts",
//       "Student Signup - Unlimited",
//       "Institute Branding",
//     ],
//   },
// ];

// const DemoPlan = () => {
//   const [selectedPlan, setSelectedPlan] = useState();
//   const navigate = useNavigate();

//   const handleClick = (planName, amount) => {
//     setSelectedPlan(planName);
//     // navigate("/payment", { state: { planName } });
//     navigate("/book-a-demo", { state: { planName, amount } });
//   };

//   return (
//     <>
//       <DemoNavbar />
//       <PageTitle title="Test Series Plans | Test Series" />
//       <Box
//         sx={{
//           mt: { xs: 12, sm: 14, md: 16 },
//           mb: 8,
//           mx: { md: 10, xs: 2 },
//         }}
//       >
//         <Typography
//           variant="h4"
//           align="center"
//           gutterBottom
//           sx={{
//             fontFamily: "Poppins, sans-serif",
//             fontWeight: "bold",
//             color: "#2C3E50",
//             mb: 2,
//           }}
//         >
//           Affordable Solutions for Your Educational Success
//         </Typography>
//         <Typography
//           variant="h6"
//           align="center"
//           gutterBottom
//           sx={{ fontFamily: "Poppins, sans-serif", color: "#7f8c8d", mb: 4 }}
//         >
//           Flexible and comprehensive plans tailored to meet all your academic
//           needs.
//         </Typography>
//         <Grid container spacing={4} justifyContent="center">
//           {plans.map((plan, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Card
//                 variant="outlined"
//                 sx={{
//                   borderRadius: "25px",
//                   boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
//                   transition: "transform 0.3s",
//                   "&:hover": {
//                     transform: "scale(1.05)",
//                   },
//                   height: "100%",
//                   backgroundColor: plan.title === "Pro" ? "#fff8e1" : "white",
//                 }}
//               >
//                 <CardContent>
//                   <Typography
//                     variant="h5"
//                     sx={{
//                       fontWeight: "bold",
//                       color: "#34495e",
//                       display: "flex",
//                       alignItems: "center",
//                       mb: 2,
//                     }}
//                   >
//                     {plan.icon} {plan.title}
//                   </Typography>
//                   <hr />

//                   <Typography
//                     variant="body2"
//                     gutterBottom
//                     sx={{
//                       mt: 2,
//                       height: { xs: "100px", md: "100px" },
// wordSpacing: "2px",
// letterSpacing: "1.5px",
// color: "#757575",
//                     }}
//                   >
//                     {plan.description}
//                   </Typography>

//                   <Typography
//                     variant="h4"
//                     align="center"
//                     sx={{
//                       color: "#f39c12",
//                       fontWeight: "bold",
//                       mb: 2,
//                       mt: 0,
//                       fontSize: { xs: "1.8rem", sm: "2.2rem", md: "2.5rem" },
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     {"\u20B9"}
//                     {plan.amount}
//                   </Typography>

//                   <Button
//                     variant="contained"
//                     fullWidth
//                     sx={{
//                       backgroundColor: "#f39c12",
//                       color: "white",
//                       "&:hover": {
//                         backgroundColor: "#d35400",
//                       },
//                       mb: 3,
//                     }}
//                     onClick={() => handleClick(plan.title, plan.amount)}
//                   >
//                     Get Plan
//                   </Button>
//                   <Typography
//                     variant="h6"
//                     sx={{ fontWeight: "bold", color: "#2C3E50", mb: 2 }}
//                   >
//                     {plan.Allfeatures}
//                   </Typography>
//                   <List dense>
//                     {plan.features.map((feature, i) => (
//                       <ListItem key={i} sx={{ mb: 0.5 }}>
//                         <ListItemIcon>
//                           <CheckIcon sx={{ color: "#f39c12" }} />
//                         </ListItemIcon>
//                         <ListItemText primary={feature} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//       <DemoFooter />
//     </>
//   );
// };

// export default DemoPlan;

import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import StarIcon from "@mui/icons-material/Star";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import DemoNavbar from "./DemoNavbar";
import { useNavigate } from "react-router-dom";
import PageTitle from "../PageTitle/PageTitle";
import DemoFooter from "./DemoFooter";

const plans = [
  {
    Allfeatures: "All features",
    icon: <StarIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />,
    title: "Standard",
    amount: "499",
    description:
      "Streamline Essentials; Manage core functionalities, collaboration with parents, and manage student data.",
    features: [
      "2 MCQ Exams -> 1 Practice Test , 1 Live Test ",
      "2 Subjective Exam -> 1 Practice Test , 1 Live Test",
      "3 PDF Notes Upload",
      "Video Lecture upload (2 Videos via YT)",
      "Student Enquiry Notifications",
      "No Reports",
      "1 Primary Account",
      "Student Signup - Unlimited",
      "Test Series Branding",
    ],
  },
  {
    Allfeatures: "All features (Pro+)",
    icon: (
      <BusinessCenterIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />
    ),
    title: "Pro",
    amount: "799",
    description:
      "Empower for Growth; Unlock essential tools for academics and financial management, and more.",
    features: [
      "10 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
      "10 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
      "15 PDF Notes Upload",
      "Video Lecture upload (10 Videos via YT)",
      "Proprietary Video Lectures Upload 4 files (1 gb Total)",
      "Student Enquiry Notifications",
      "View Reports",
      "1 Primary Account 2 Teacher Accounts",
      "Student Signup - Unlimited",
      "Test Series Branding",
    ],
  },
  {
    Allfeatures: "All features (Elite+)",
    icon: <WhatshotIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />,
    title: "Elite",
    amount: "999",
    description:
      "Drive Efficiency; Unleash the power of integrations, from devices & institutions, to HRMS, and beyond.",
    features: [
      "50 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
      "50 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
      "20 PDF Notes Upload",
      "Video Lecture upload (25 Videos via YT)",
      "Proprietary Video Lectures Upload 10 files (2 gb Total)",
      "Student Enquiry Notifications",
      "Download Reports",
      "2 Primary Accounts 5 Teacher Accounts",
      "Student Signup - Unlimited",
      "Institute Branding",
    ],
  },
];

const DemoPlan = () => {
  const navigate = useNavigate();

  const handleClick = (planName, amount) => {
    navigate("/book-a-demo", { state: { planName, amount } });
  };

  return (
    <>
      <DemoNavbar />
      <PageTitle title="Test Series Plans | Test Series" />
      <Box
        sx={{mb: 8, mt: { xs: 15 , md: 15 }, pl:{xs: 3, md:12},  pr:{xs: 3,md:12}, minHeight: "100vh" }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            color: "#2C3E50",
            mb: 2,
          }}
        >
          Affordable Solutions for Your Educational Success
        </Typography>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ fontFamily: "Poppins, sans-serif", color: "#7f8c8d", mb: 4 }}
        >
          Flexible and comprehensive plans tailored to meet all your academic
          needs.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "25px",
                  boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  height: "100%",
                  backgroundColor: plan.title === "Pro" ? "#fff8e1" : "white",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "#34495e",
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    {plan.icon} {plan.title}
                  </Typography>
                  <hr />
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      mt: 2,
                      mb: { lg: -5, md: 0, xs: -2, sm: -2 },
                      height: "100px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#757575",
                      wordSpacing: "2px",
                      letterSpacing: "1.5px",
                    }}
                  >
                    {plan.description}
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "#f39c12",
                      fontWeight: "bold",
                      mb: 2,
                      fontSize: { xs: "1.8rem", sm: "2.2rem", md: "2.5rem" },
                    }}
                  >
                    {"\u20B9"}
                    {plan.amount}
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#f39c12",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#d35400",
                      },
                      mb: 3,
                    }}
                    onClick={() => handleClick(plan.title, plan.amount)}
                  >
                    Get Plan
                  </Button>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#2C3E50", mb: 2 }}
                  >
                    {plan.Allfeatures}
                  </Typography>
                  <List dense>
                    {plan.features.map((feature, i) => (
                      <ListItem key={i} sx={{ mb: 0.5 }}>
                        <ListItemIcon>
                          <CheckIcon sx={{ color: "#f39c12" }} />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <DemoFooter />
    </>
  );
};

export default DemoPlan;
