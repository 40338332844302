import React from "react";
import DemoNavbar from "../TestSeriesInformation/DemoNavbar";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { Box, Typography, Paper, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import Footer from "../TestSeriesInformation/DemoFooter";

const PhonePe = () => {
    const location = useLocation();
    const { bookADemo } = location.state || {};

    console.log(bookADemo.name);
    console.log(bookADemo, "book a demo");

    
  return (
    <>
      <DemoNavbar />
      <PageTitle title="PhonePe Payment | Test Series" />
      <Box
        sx={{
          mt: { xs: 16, sm: 16, md: 16, lg: 18 },
          mb: 8,
          mx: { md: 12, xs: 2 },
          minHeight: "100vh",
        }}
      >
        <h1>PhonePe Payment</h1>
      </Box> 
    </>
  );
};

export default PhonePe;
