import React, { useState, useEffect } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Button,
  Typography,
  Container,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { message } from "antd";
import axios from "../../axiosInstance";

const EditStudentQuestion = () => {
  let { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const [studentQuestion, setStudentQuestion] = useState("");
  const [question, setQuestion] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState("");


  // Get all video exams
  const getStudentQuestions = async () => {
    try {
      const response = await axios.post(
        `/api/v1/question/video/exam/admin-get-question-videoExam`,
        {
          id,
        }
      );
      if (response?.data?.success === true) {
        setStudentQuestion(response?.data.studentQuestion);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getStudentQuestions();
  }, [id]);

  // Handle Submit Question
  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    const questionData = {
      id,
      question: question || studentQuestion?.question,
      correctAnswers,
      vendorId: user?._id,
    };
    try {
      const response = await axios.put(
        "/api/v1/question/video/exam/admin-update-question-videoExam",
        questionData
      );
      if (response.data.success === true) {
        message.success(response?.data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Student Question Answer| Test Series" />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
       Add & Edit Student Question 
      </Typography>

      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink> 
        <MUILink
          component={Link}
          to="/admin/student/question/video/exam"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Student Question List
        </MUILink> 
        <Typography sx={{ color: "primary.main" }}>Student Question</Typography>
      </Breadcrumbs>

      <Container
        onSubmit={handleSubmitQuestion}
        component="form"
        className="form_style border-style"
        maxWidth
      >
        <ReactQuill
          id="question"
          label="Question"
          name="question"
          placeholder="Question"
          value={question || studentQuestion?.question}
          onChange={setQuestion}
          style={{
            marginTop: "12px",
          }}
        />

        <ReactQuill
          sx={{
            mt: 3,
          }}
          id="answer"
          label="Answer"
          name="answer"
          placeholder="Answer"
          value={correctAnswers || studentQuestion?.correctAnswers}
          onChange={setCorrectAnswers}
          style={{
            marginTop: "25px",
          }}
        />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 5,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Submit Answer
        </Button>
      </Container>
    </>
  );
};

export default EditStudentQuestion;
