import React, { useState, useEffect } from "react";
import { message } from "antd";
import {
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import DemoNavbar from "./DemoNavbar";
import PageTitle from "../PageTitle/PageTitle";
import axios from "../../axiosInstance";
import DemoFooter from "./DemoFooter";

const BookADemo = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestedPlan, setRequestedPlan] = useState("");
  const [planAmount, setPlanAmount] = useState(null);

  const location = useLocation();
  const { planName: selectedPlanName, amount } = location.state || {};

  // Mapping of plans to their amounts
  const planAmounts = {
    Standard: 499,
    Pro: 799,
    Elite: 999,
  };

  useEffect(() => {
    if (selectedPlanName) {
      setRequestedPlan(selectedPlanName);
      setPlanAmount(planAmounts[selectedPlanName]);
    }
  }, [selectedPlanName]);

  const handlePlanChange = (e) => {
    const plan = e.target.value;
    setRequestedPlan(plan);
    setPlanAmount(planAmounts[plan]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const transactionID = "TXN" + Date.now();

    const payment = {
      transactionID,
      name,
      number: phoneNumber,
      email,
      requestedPlan,
      amount: planAmount,
    };
    try {
      const response = await axios.post("/api/payment", payment);
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        message.error(response.data.message || "Failed to initiate payment.");
      }
    } catch (error) {
      message.error(
        error.response.data.message ||
          "An error occurred while processing the payment."
      );
    }
  };

  // Book A Demo
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true); // Start loading

  //   const bookADemo = {
  //     name,
  //     institutionName,
  //     phoneNumber,
  //     organizationRole,
  //     email,
  //     city,
  //     requestedPlan,
  //   };
  //   try {
  //     const { data } = await axios.post("/api/v1/demo/book-a-demo", bookADemo);
  //     if (data.success === true) {
  //       message.success(data.message);
  //       // Clear form fields
  //       setName("");
  //       setInstitutionName("");
  //       setPhoneNumber("");
  //       setOrganizationRole("");
  //       setEmail("");
  //       setCity("");
  //       setRequestedPlan("");
  //     } else {
  //       message.error(data?.message);
  //     }
  //   } catch (error) {
  //     message.error(error?.response?.data?.message);
  //   } finally {
  //     setLoading(false); // Stop loading
  //   }
  // };

  return (
    <>
      <DemoNavbar />
      <PageTitle title="Book a demo | Test Series" />
      <Box
        // sx={{
        //   mt: { xs: 8, md: 15 },
        //   mb: 5,
        //   pl: { xs: 3, md: 12 },
        //   pr: { xs: 3, md: 12 },
        //   minHeight: "100vh",
        // }}
        sx={{
          mt: { xs: 16, sm: 16, md: 16, lg: 18 },
          mb: 8,
          mx: { md: 12, xs: 2 },
          minHeight: "100vh",
          // backgroundColor: "#f9f9f9",
          borderRadius: "25px",
        }}
      >
        {/* Book A Demo */}
        <Card
          variant="outlined"
          sx={{
            mt: 5,
            mb: 13,
            p: 3,
            pl: 5,
            // minHeight: "100vh",
            borderRadius: "25px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            wordSpacing: "2px",
            letterSpacing: "1.5px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              mt: 3,
              mb: 3,
              padding: { xs: 1, sm: 3, md: 0 },
            }}
          >
            {/* Left section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                background: "#F3F7FE",
                borderRadius: { xs: "25px", md: "25px 0 0 25px" },
                p: { xs: 2, md: 4 },
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 500,
                  mb: 2,
                  lineHeight: 1.5,
                  mr: { xs: 1, md: 4 },
                  ml: { xs: 1, md: 4 },
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Proceed To Pay
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  lineHeight: 2,
                  mr: { xs: 2, md: 4 },
                  ml: { xs: 2, md: 4 },
                  mt: 5,
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Ready to see how testSeries can revolutionize your exam
                preparation? Take the first step by booking a demo session with
                us.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  mr: { xs: 2, md: 4 },
                  ml: { xs: 2, md: 4 },
                  display: "flex",
                  alignItems: "center",
                  mt: 5,
                  wordSpacing: "2px",
                  letterSpacing: { xs: 0, sm: 1, md: 1 },
                  textAlign: { xs: "center", md: "left" },
                  flexDirection: { xs: "column", sm: "row" },
                  maxWidth: "100%",
                  overflowWrap: "break-word",
                }}
              >
                <PhoneIcon sx={{ mr: 2, color: "#1976d2" }} />
                Contact Number: +91-7891555777, +91-8442025283
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  mr: { xs: 2, md: 4 },
                  ml: { xs: 2, md: 4 },
                  mt: 5,
                  display: "flex",
                  alignItems: "center",
                  wordSpacing: "2px",
                  letterSpacing: { xs: 0, sm: 1, md: 1 },
                  textAlign: { xs: "center", md: "left" },
                  flexDirection: { xs: "column", sm: "row" },
                  maxWidth: "100%",
                  overflowWrap: "break-word",
                }}
              >
                <EmailIcon sx={{ mr: 2, color: "#1976d2" }} />
                E-mail: testseries@gatiktech.com
              </Typography>
            </Grid>

            {/* Right section */}
            <Grid item xs={12} md={6}>
              <Box onSubmit={handleSubmit} component="form" maxWidth>
                {/* Form fields */}
                <TextField
                  sx={{
                    mb: 3,
                    mt: { xs: 2, sm: 2, md: 0, xl: 0 },
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="name"
                  label="Enter your full name"
                  name="name"
                  type="name"
                  placeholder="GatikAI Technologies "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                {/* <TextField
                  sx={{
                    mb: 3,
                    mt: 2,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="institutionName"
                  label="Enter your institution name"
                  name="institutionName"
                  placeholder="xyz institute"
                  value={institutionName}
                  onChange={(e) => setInstitutionName(e.target.value)}
                /> */}

                <TextField
                  sx={{
                    mb: 3,
                    mt: 2,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="email"
                  label="Enter your email"
                  name="email"
                  type="email"
                  placeholder="i.e. xyz@domain.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  sx={{
                    mb: 3,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    "& input[type='number']": {
                      MozAppearance: "textfield",
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="phoneNumber"
                  label="Enter your contact number"
                  name="phoneNumber"
                  type="number"
                  placeholder="i.e. 9134567890"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />

                {/* <TextField
                    sx={{
                      mb: 3,
                      mt: 2,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    id="organizationRole"
                    label="Your role in the organization?"
                    name="organizationRole"
                    placeholder="i.e Principal"
                    value={organizationRole}
                    onChange={(e) => setOrganizationRole(e.target.value)}
                  /> */}

                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel shrink htmlFor="reports">
                    Select Plan Name
                  </InputLabel>
                  <Select
                    label="Select Plan Name"
                    value={requestedPlan}
                    onChange={handlePlanChange}
                    displayEmpty
                    id="planName"
                    name="planName"
                    placeholder="Select Plan Name"
                    sx={{
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Plan Name
                    </MenuItem>
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Pro">Pro</MenuItem>
                    <MenuItem value="Elite">Elite</MenuItem>
                  </Select>
                  <input
                    type="hidden"
                    value={planAmount || ""}
                    name="planAmount"
                  />
                </FormControl>

                <Button
                  sx={{
                    mt: 3,
                    p: 1.5,
                    wordSpacing: "2px",
                    letterSpacing: "1px",
                  }}
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Proceed to pay "}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <DemoFooter />
    </>
  );
};

export default BookADemo;
