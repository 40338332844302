import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import { message } from "antd";
import axios from "../axiosInstance";
import useInstituteUser from "./GetInstituteUser";

const JobNotification = () => {
  const [Jobs, setJobs] = useState([]);
  const studentCourse = useInstituteUser();

  // Fetch job notifications
  const getAllJobPost = async () => {
    try {
      const response = await axios.post(`/api/v1/admin/get-job-institute`, {
        studentCourse,
      });
      if (response?.data?.success === true) {
        setJobs(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (studentCourse) {
      getAllJobPost(studentCourse);
    }
  }, [studentCourse]);

  return (
    <>
      {Jobs && Jobs.length > 0 ? (
        <Box sx={{ mr: { md: 6, xs: 2 }, ml: { md: 6, xs: 2 }, mb: 5 }}>
          <Typography
            style={{
              fontWeight: "600px",
              fontSize: "22px",
              textAlign: "center",
            }}
            sx={{ color: "black", pb: 3, mt: 3 }}
          >
            <b>Latest Job Notifications</b>
          </Typography>
          <Grid container spacing={3}>
            {Jobs.map((job, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    transition: "transform 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      transform: "translateY(-8px)",
                      boxShadow:
                        "0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                    },
                    borderRadius: "15px",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                    padding: "16px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="courses_title"
                      sx={{
                        letterSpacing: ".1rem",
                        fontSize: { xs: "18px", sm: "20px" },
                        marginBottom: "12px",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        color: "#1E88E5",
                      }}
                    >
                      {job.latestPostName}
                    </Typography>

                    <Typography
                      className="courses_desc"
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mt: 1,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {job.latestNotificationDetails}
                    </Typography>

                    {/* <Typography
                      className="courses_desc"
                      variant="body2"
                      color="text.secondary"
                     sx={{
                          mt: 2,
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#34495E",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                    >
                      <strong>Form Last Date:</strong> {job?.latestLastDate}
                    </Typography> */}

                    <Typography
                      className="courses_desc"
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mt: 2,
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#34495E",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong>Form Last Date:</strong>{" "}
                      {new Date(job?.latestLastDate)
                        .toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(/ /g, "-")}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      sx={{
                        mt: 2,
                        borderRadius: "50px",
                        textTransform: "none",
                        fontFamily: "Poppins, sans-serif",
                        letterSpacing: ".1rem",
                      }}
                      href={job.urlJobNotification}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <>{null}</>
      )}
    </>
  );
};

export default JobNotification;
