// import React, { useState } from "react";
// import { Modal, message } from "antd";
// import IconButton from "@mui/material/IconButton";
// import EditIcon from "@mui/icons-material/Edit";
// import {
//   Container,
//   TextField,
//   Button,
//   Box,
//   CircularProgress,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import axios from "../../axiosInstance";
// import imageCompression from 'browser-image-compression';

// const EditTeacher = ({
//   refreshData,
//   teacherId,
//   instituteTeacher_Name,
//   instituteTeacher_Subject,
//   instituteTeacher_Experience,
//   instituteTeacher_Image,
//   instituteId,
//   vendorId,
// }) => {
//   const [teacherName, setTeacherName] = useState(instituteTeacher_Name || "");
//   const [teacherSubject, setTeacherSubject] = useState(
//     instituteTeacher_Subject || ""
//   );
//   const [teacherExperience, setTeacherExperience] = useState(
//     instituteTeacher_Experience || ""
//   );
//   const [image, setImage] = useState(instituteTeacher_Image || "");
//   const [imageBase64, setImageBase64] = useState("");
//   const [loading, setLoading] = useState(false);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const showModal = () => setIsModalOpen(true);

//   const VisuallyHiddenInput = styled("input")({
//     display: "none",
//   });

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

// const handleImageUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImageBase64(reader.result);
//       };
//       reader.readAsDataURL(file);
//       setImage(URL.createObjectURL(file));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!teacherName || !teacherSubject || !teacherExperience) {
//       message.error("Please fill in all required fields.");
//       return;
//     }

//     setLoading(true);

//     Modal.confirm({
//       title: "Are you sure you want to Edit this Institute Teacher?",
//       content: "Confirm to apply the changes made to this institute teacher.",
//       okText: "Yes, Edit",
//       cancelText: "No, Cancel",
//       onOk: async () => {
//         const updatedTeacher = {
//           teacherId,
//           teacherName,
//           teacherSubject,
//           teacherExperience,
//           teacherImage: imageBase64 || image,
//           instituteId,
//           vendorId,
//         };

//         try {
//           const { data } = await axios.put(
//             "/api/v1/vendor/admin-update-teacher-institute",
//             updatedTeacher
//           );
//           if (data.success) {
//             message.success(data.message);
//             refreshData();
//             setIsModalOpen(false);
//           } else {
//             message.error(data.message || "Failed to update teacher details.");
//           }
//         } catch (error) {
//           message.error(
//             error?.response?.data?.message || "Error updating the teacher."
//           );
//         } finally {
//           setLoading(false);
//         }
//       },
//     });
//   };

//   return (
//     <div>
//       <IconButton aria-label="edit" onClick={showModal}>
//         <EditIcon sx={{ color: "#1976d2" }} />
//       </IconButton>

//       <Modal
//         title="Edit Institute Teacher"
//         open={isModalOpen}
//         onCancel={handleCancel}
//         footer={null}
//       >
//         <Container
//           onSubmit={handleSubmit}
//           component="form"
//           className="form_style border-style"
//           maxWidth="sm"
//         >
//           <TextField
//             fullWidth
//             id="teacherName"
//             label="Teacher Name *"
//             placeholder="Teacher Name"
//             value={teacherName}
//             onChange={(e) => setTeacherName(e.target.value)}
//             required
//             sx={{ mb: 3 }}
//           />

//           <TextField
//             fullWidth
//             id="teacherSubject"
//             label="Teacher Subject *"
//             placeholder="Teacher Subject"
//             value={teacherSubject}
//             onChange={(e) => setTeacherSubject(e.target.value)}
//             required
//             sx={{ mb: 3 }}
//           />

//           <TextField
//             fullWidth
//             id="teacherExperience"
//             label="Teacher Experience (Years) *"
//             placeholder="Teacher Experience (Years)"
//             type="number"
//             value={teacherExperience}
//             onChange={(e) => setTeacherExperience(e.target.value)}
//             required
//             sx={{ mb: 3 }}
//           />

//           <Box>
//             <VisuallyHiddenInput
//               accept="image/*"
//               id="contained-button-file"
//               type="file"
//               onChange={handleImageUpload}
//             />
//             <label htmlFor="contained-button-file">
//               <Button
//                 variant="outlined"
//                 color="primary"
//                 component="span"
//                 startIcon={<CloudUploadIcon />}
//               >
//                 Teacher Image
//               </Button>
//             </label>
//             {image && (
//               <Box mt={3} display="flex" alignItems="center">
//                 <img
//                   src={image}
//                   alt="avatar"
//                   style={{
//                     height: "100px",
//                     width: "100px",
//                     borderRadius: "50%",
//                     objectFit: "cover",
//                   }}
//                 />
//               </Box>
//             )}
//           </Box>

//           <Button
//             variant="contained"
//             type="submit"
//             fullWidth
//             disabled={loading}
//             sx={{ mt: 3 }}
//           >
//             {loading ? <CircularProgress size={24} /> : "Edit Teacher"}
//           </Button>
//         </Container>
//       </Modal>
//     </div>
//   );
// };

// export default EditTeacher;

import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  Container,
  TextField,
  Button,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import imageCompression from "browser-image-compression";
import axios from "../../axiosInstance";

const EditTeacher = ({
  refreshData,
  teacherId,
  instituteTeacher_Name,
  instituteTeacher_Subject,
  instituteTeacher_Experience,
  instituteTeacher_Image,
  instituteTeacher_Email,
  instituteTeacher_Phone,
  instituteTeacher_Public,
  instituteId,
  vendorId,
}) => {
  const [teacherName, setTeacherName] = useState(instituteTeacher_Name || "");
  const [teacherSubject, setTeacherSubject] = useState(
    instituteTeacher_Subject || ""
  );
  const [teacherExperience, setTeacherExperience] = useState(
    instituteTeacher_Experience || ""
  );
  const [image, setImage] = useState(instituteTeacher_Image || "");
  const [teacherEmail, setTeacherEmail] = useState(
    instituteTeacher_Email || ""
  );
  const [teacherPhone, setTeacherPhone] = useState(
    instituteTeacher_Phone || ""
  );
  const [teacherPublic, setTeacherPublic] = useState(
    instituteTeacher_Public || ""
  );
  const [imageBase64, setImageBase64] = useState("");
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);

  const VisuallyHiddenInput = styled("input")({
    display: "none",
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const maxSizeInMB = 25;

    if (file) {
      if (file.size / (1024 * 1024) > maxSizeInMB) {
        message.error(`Image size should not exceed ${maxSizeInMB} MB.`);
        return;
      }
      // Compress the image before conversion to base64
      const options = {
        maxSizeMB: 25,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = async () => {
          const imageBase64 = reader.result;
          setImage(imageBase64);
          setImageBase64(imageBase64);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!teacherName || !teacherSubject || !teacherExperience) {
      message.error("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    Modal.confirm({
      title: "Are you sure you want to edit this teacher?",
      content: "Confirm to apply the changes.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const updatedTeacher = {
          teacherId,
          teacherName,
          teacherSubject,
          teacherExperience,
          teacherImage: imageBase64,
          teacherEmail,
          teacherPhone,
          teacherPublic,
          instituteId,
          vendorId,
        };

        try {
          const { data } = await axios.put(
            "/api/v1/vendor/admin-update-teacher-institute",
            updatedTeacher
          );

          if (data.success) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data.message || "Failed to update teacher details.");
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error updating the teacher."
          );
        } finally {
          setLoading(false);
        }
      },
    });
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>

      <Modal
        title="Edit Institute Teacher"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="editTeacher-form_style border-style"
          maxWidth="sm"
        >
          <TextField
            fullWidth
            id="teacherName"
            label="Teacher Name"
            placeholder="Teacher Name"
            value={teacherName}
            onChange={(e) => setTeacherName(e.target.value)}
            required
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            id="teacherSubject"
            label="Teacher Subject"
            placeholder="Teacher Subject"
            value={teacherSubject}
            onChange={(e) => setTeacherSubject(e.target.value)}
            required
            sx={{ mb: 3 }}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& input[type='number']": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  display: "none",
                },
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="teacherExperience"
            label="Teacher Experience (Years)"
            placeholder="Teacher Experience (Years)"
            type="number"
            value={teacherExperience}
            onChange={(e) => setTeacherExperience(e.target.value)}
            required
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="teacherEmail"
            label="Teacher Email"
            name="teacherEmail"
            type="email"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Teacher Email"
            value={teacherEmail}
            onChange={(e) => setTeacherEmail(e.target.value)}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& input[type='number']": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  display: "none",
                },
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="teacherPhone"
            label="Teacher Phone"
            name="teacherPhone"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Teacher Phone"
            value={teacherPhone}
            onChange={(e) => setTeacherPhone(e.target.value)}
          />

          <Box sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="teacher-public-label">Teacher Public</InputLabel>
              <Select
                labelId="teacher-public-label"
                id="teacher-public"
                value={teacherPublic}
                label="Teacher Public"
                onChange={(e) => setTeacherPublic(e.target.value)}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box>
            <VisuallyHiddenInput
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload Image
              </Button>
            </label>
            {image && (
              <Box mt={3} display="flex" alignItems="center">
                <img
                  src={image}
                  alt="avatar"
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            )}
          </Box>

          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={loading}
            sx={{ mt: 3 }}
          >
            {loading ? <CircularProgress size={24} /> : "Edit Teacher"}
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditTeacher;
