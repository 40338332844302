import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditPlan from "./EditPlan";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Plans() {
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [planAmount, setPlanAmount] = useState("");
  const [mcqExam, setMcqExam] = useState("");
  const [subjectiveExam, setSubjectiveExam] = useState("");
  const [PDFNotes, setPDFNotes] = useState("");
  const [courseVideo, setCourseVideo] = useState("");
  const [videoExam, setVideoExam] = useState("")
  const [reports, setReports] = useState("");
  const [primaryAccount, setPrimaryAccount] = useState("");
  const [teacherAccount, setTeacherAccount] = useState("");
  const [plans, setPlans] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Plan Data
  const getAllPlan = async () => {
    try {
      const response = await axios.get(`/api/v1/plan/admin-get-plan`);
      if (response?.data?.success === true) {
        setPlans(response?.data?.plan);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPlan();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Plan
  const { confirm } = Modal;
  const deletePlan = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Plan?",
      content: "Once deleted, this action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/plan/admin-delete-plan",
            {
              data: { id },
            }
          );

          if (data.success === true) {
            message.success(data?.message);
            getAllPlan();
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete plan"
          );
        }
      },
      onCancel() {
        console.log("Plan deletion canceled");
      },
    });
  };

  const columns = [
    // {
    //   field: "rowNumber",
    //   headerName: "#",
    //   width: 20,
    //   sortable: false,
    // },
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deletePlan(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditPlan
            refreshData={getAllPlan}
            plans={value?.row?._id}
            name={value?.row?.name}
            amount={value?.row?.planAmount}
            examLimit={value?.row?.examLimit}
            subjectiveExamLimit={value?.row?.subjectiveExamLimit}
            PDFNotesLimit={value?.row?.PDFNotesLimit}
            courseVideoLimit={value?.row?.courseVideoLimit}
            videoExamLimit={value?.row?.videoExamLimit}
            reportsLimit={value?.row?.reportsLimit}
            primaryAccountLimit={value?.row?.primaryAccountLimit}
            teacherAccountLimit={value?.row?.teacherAccountLimit}
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Plan Name",
      width: 120,
      editable: true,
    },
    {
      field: "planAmount",
      headerName: "Plan Amount",
      width: 120,
      editable: true,
    },
    {
      field: "examLimit",
      headerName: "MCQ Exam",
      width: 110,
    },
    {
      field: "subjectiveExamLimit",
      headerName: "Subjective Exam ",
      width: 130,
    },
    {
      field: "PDFNotesLimit",
      headerName: "PDF Notes",
      width: 120,
    },
    {
      field: "courseVideoLimit",
      headerName: "Courses Video",
      width: 120,
    },
    {
      field: "videoExamLimit",
      headerName: "Video Exam",
      width: 120,
    },
    {
      field: "reportsLimit",
      headerName: "Reports",
      width: 120,
      editable: true,
    },
    {
      field: "primaryAccountLimit",
      headerName: "Primary Account",
      width: 140,
    },
    {
      field: "teacherAccountLimit",
      headerName: "Teacher Account",
      width: 140,
    },
    {
      field: "createdAt",
      headerName: "Plan Date",
      width: 120,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  // Add Plans
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addPlan = {
      name: name,
      planAmount,
      examLimit: mcqExam,
      subjectiveExamLimit: subjectiveExam,
      PDFNotesLimit: PDFNotes,
      courseVideoLimit: courseVideo,
      videoExamLimit: videoExam,
      reportsLimit: reports,
      primaryAccountLimit: primaryAccount,
      teacherAccountLimit: teacherAccount,
    };
    try {
      const { data } = await axios.post("/api/v1/plan/admin-add-plan", addPlan);
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setName("");
        setMcqExam("");
        setSubjectiveExam("");
        setPDFNotes("");
        setCourseVideo("");
        setVideoExam("");
        setReports("");
        setPrimaryAccount("");
        setTeacherAccount("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Vendor Plans | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Vendor Plans
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="#"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Super Admin
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Vendor Plans</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Plans List" {...TabPanel(0)} />
            <Tab label="Add Plans" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Plan List */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Vendor Plan List
          </Typography>
          <Paper
            sx={{
              mt: 3,
              bgcolor: "white",
              borderRadius: 3,
              overflow: "hidden",
            }}
          >
            <Box sx={{ height: 450, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={plans}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/* Add Plans */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Vendor Plan
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Plan Name *"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Plan Name (Standard, Pro, Elite)"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="planAmount"
              label="Plan Amount"
              name="planAmount"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Plan Amount"
              value={planAmount}
              onChange={(e) => setPlanAmount(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="mcqExam"
              label="MCQ Exam"
              name="mcqExam"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="MCQ Exam Limit"
              value={mcqExam}
              onChange={(e) => setMcqExam(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="subjectiveExam"
              label="Subjective Exam"
              name="subjectiveExam"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Subjective Exam Limit"
              value={subjectiveExam}
              onChange={(e) => setSubjectiveExam(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="PDFNotes"
              label="PDF Notes"
              name="PDFNotes"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="PDF Notes Limit"
              value={PDFNotes}
              onChange={(e) => setPDFNotes(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="courseVideo"
              label="Course Video"
              name="courseVideo"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Course Video Limit"
              value={courseVideo}
              onChange={(e) => setCourseVideo(e.target.value)}
            />
             <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="videoExam"
              label="Video Exam"
              name="videoExam"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Video Exam Limit"
              value={videoExam}
              onChange={(e) => setVideoExam(e.target.value)}
            />

            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel shrink htmlFor="reports">
                User Reports
              </InputLabel>
              <Select
                label="User Reports"
                value={reports}
                onChange={(e) => setReports(e.target.value)}
                displayEmpty
                id="reports"
                name="reports"
                placeholder="User Reports Limit"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
              >
                <MenuItem value="" disabled>
                  Select Report Access
                </MenuItem>
                <MenuItem value="No Reports">No Reports</MenuItem>
                <MenuItem value="View Reports">View Reports</MenuItem>
                <MenuItem value="Download Reports">Download Reports</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="primaryAccount"
              label="Primary Account"
              name="primaryAccount"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Primary Account Limit"
              value={primaryAccount}
              onChange={(e) => setPrimaryAccount(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teacherAccount"
              label="Teacher Account"
              name="teacherAccount"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Teacher Accounts Limit"
              value={teacherAccount}
              onChange={(e) => setTeacherAccount(e.target.value)}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Add Plan
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
