/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  TextField,
  Badge,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import Footer from "../Components/Footer";
import axios from "../axiosInstance";
import useInstituteUser from "../Components/GetInstituteUser";

const Videos = () => {
  const studentCourse = useInstituteUser();
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState({});
  const [selectedCourse, setSelectedCourse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const currentDate = moment().startOf("day");
  const navigate = useNavigate();

  // Get All Video Data
  const getAllVideos = async () => {
    try {
      const response = await axios.post("/api/v1/admin/get-all-video", {
        studentCourse,
      });

      const videoData = response?.data?.data || [];
      // Map instituteCourseId to studentCourseName using studentCourse array
      const updatedVideoData = videoData.map((video) => {
        const matchedCourse = studentCourse.find(
          (course) => course.studentCourseId === video.instituteCourseId
        );
        return {
          ...video,
          instituteCourseId:
            matchedCourse?.studentCourseName || video.instituteCourseId,
        };
      });

      setVideos(updatedVideoData);
      setFilteredVideos(updatedVideoData);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    if (studentCourse) {
      getAllVideos(studentCourse);
    }
  }, [studentCourse]);

  // Search function
  const searchAllVideo = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  // Filter videos based on course and search query
  const getFilteredVideos = () => {
    return videos.filter((video) => {
      const matchesSearch =
        video.title.toLowerCase().includes(searchQuery) ||
        video.description.toLowerCase().includes(searchQuery);

      const matchesCourse = selectedCourse
        ? video.instituteCourseId === selectedCourse
        : true;

      return matchesSearch && matchesCourse;
    });
  };

  // Handle course selection change
  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const courses = Array.isArray(studentCourse) ? studentCourse : [];

  const isYouTubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  const getYouTubeThumbnailUrl = (url) => {
    const videoIdMatch = url.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    const videoId = videoIdMatch ? videoIdMatch[1] : null;
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null;
  };

  return (
    <div>
      <Navbar />
      <PageTitle title="Courses | Test Series" />
      <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
        <Grid container spacing={3}>
          {/* Search Input */}
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              sx={{
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputLabel-root": {
                  fontWeight: "bold",
                  color: "#1976d2",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}
              fullWidth
              id="text"
              name="text"
              placeholder="Search by Title and Description"
              value={searchQuery}
              onChange={searchAllVideo}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          {/* Course Name Dropdown */}
          <Grid item xs={12} sm={4} md={4}>
            <FormControl
              fullWidth
              sx={{
                mb: 3,
                bgcolor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <InputLabel sx={{ fontWeight: "bold", color: "#1976d2" }}>
                Course Name
              </InputLabel>
              <Select
                value={selectedCourse}
                onChange={handleCourseChange}
                label="Course Name"
                sx={{
                  bgcolor: "white",
                  borderRadius: "8px",
                  "& .MuiSelect-icon": {
                    color: "#1976d2",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                }}
              >
                {/* Add an option for 'All Courses' */}
                <MenuItem value="">
                  <em>All Courses</em>
                </MenuItem>
                {/* Check if courses are available, then render the items */}
                {courses.length > 0 ? (
                  courses.map((course) => (
                    <MenuItem
                      key={course.studentCourseId}
                      value={course.studentCourseName}
                    >
                      {course.studentCourseName}
                    </MenuItem>
                  ))
                ) : (
                  // Fallback if there are no courses
                  <MenuItem disabled>No courses available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
        <Box
          sx={{
            pt: 5,
            pb: 5,
            minHeight: "83vh",
            mr: { md: 12, xs: 2 },
            ml: { md: 12, xs: 2 },
          }}
        >
          <Grid container spacing={8}>
            {getFilteredVideos().length > 0 ? (
              getFilteredVideos().map((video, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Badge
                    badgeContent="Upcoming"
                    color="primary"
                    invisible={moment(video.startDate).isSameOrBefore(
                      currentDate
                    )}
                  >
                    <Card
                      sx={{
                        transition: "0.3s !important",
                        "&:hover": { transform: "scale(1.05)" },
                        borderRadius: "15px",
                        boxShadow:
                          "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                        height: { xs: 400, sm: 400, md: 450 },
                        width: "100% !important",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardMedia
                        component="div"
                        title="Video"
                        sx={{
                          height: { xs: 160, sm: 200, md: 240 },
                          overflow: "hidden",
                        }}
                      >
                        {isYouTubeUrl(video.url) ? (
                          <Box sx={{ height: "100%", width: "100%" }}>
                            <img
                              src={getYouTubeThumbnailUrl(video.url)}
                              alt="YouTube Thumbnail"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        ) : (
                          <video
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            controls={false}
                          >
                            <source src={video.url} alt="Upload Video" />
                          </video>
                        )}
                      </CardMedia>
                      <CardContent
                        sx={{ flexGrow: 1, padding: { xs: 1, sm: 2 } }}
                      >
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{
                            letterSpacing: ".1rem",
                            fontSize: { xs: "18px", sm: "20px" },
                            marginBottom: "12px",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            color: "#2C3E50",
                          }}
                        >
                          {video.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            mt: 1,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            fontSize: "15px",
                            letterSpacing: ".1rem",
                            fontFamily: "Poppins, sans-serif",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: video.description,
                          }}
                        />
                      </CardContent>
                      <CardActions
                        sx={{ mt: "auto", padding: { xs: 1, sm: 2 } }}
                      >
                        {moment(video.startDate).isSameOrBefore(currentDate) ? (
                          <Button
                            onClick={() => navigate(`/video/${video._id}`)}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            sx={{
                              mt: 1,
                              mb: 2,
                              borderRadius: "50px",
                              textTransform: "none",
                              fontFamily: "Poppins, sans-serif",
                              letterSpacing: ".1rem",
                            }}
                          >
                            Start Watching
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            disabled
                            sx={{
                              mt: 1,
                              mb: 2,
                              borderRadius: "50px",
                              textTransform: "none",
                              fontFamily: "Poppins, sans-serif",
                              letterSpacing: ".1rem",
                            }}
                          >
                            Upcoming Courses
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Badge>
                </Grid>
              ))
            ) : (
              <Box
                style={{
                  color: "#878787",
                  margin: "70px 80px",
                  fontSize: 18,
                }}
              >
                No Available Courses!!
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Videos;


/* eslint-disable jsx-a11y/iframe-has-title */
// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   CardMedia,
//   Button,
//   Typography,
//   TextField,
//   Badge,
//   IconButton,
// } from "@mui/material";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { message } from "antd";
// import SearchIcon from "@mui/icons-material/Search";
// import Navbar from "../Components/Navbar";
// import PageTitle from "../Components/PageTitle/PageTitle";
// import Footer from "../Components/Footer";
// import axios from "../axiosInstance";
// import useInstituteUser from "../Components/GetInstituteUser";

// const Videos = () => {
//   const [videos, setVideos] = useState([]);
//   const [filteredVideos, setFilteredVideos] = useState({});
//   const studentCourse = useInstituteUser();
//   const [isPlaying, setIsPlaying] = useState(false);
//   const currentDate = moment().startOf("day");
//   const navigate = useNavigate();
//   const [search, setSearch] = useState([]);

//   // Get All Video Data
//   const getAllVideos = async () => {
//     try {
//       const response = await axios.post("/api/v1/admin/get-all-video", {
//         studentCourse,
//       });
//       const videoData = response?.data?.data || [];
//       // Map instituteCourseId to studentCourseName using studentCourse array
//       const updatedVideoData = videoData.map((video) => {
//         const matchedCourse = studentCourse.find(
//           (course) => course.studentCourseId === video.instituteCourseId
//         );
//         return {
//           ...video,
//           instituteCourseId:
//             matchedCourse?.studentCourseName || video.instituteCourseId,
//         };
//       });
//       setVideos(updatedVideoData);
//       setSearch(updatedVideoData);
//       filterVideosByInstituteCourseId(updatedVideoData);
//     } catch (error) {
//       message.error(error.response?.data?.message);
//     }
//   };
//   // Group videos by instituteCourseId (or updated field)
//   const filterVideosByInstituteCourseId = (videoData) => {
//     const groupedVideos = videoData.reduce((acc, video) => {
//       const key = video.instituteCourseId || "Other";
//       if (!acc[key]) acc[key] = [];
//       acc[key].push(video);
//       return acc;
//     }, {});
//     setFilteredVideos(groupedVideos);
//   };
//   useEffect(() => {
//     if (studentCourse) {
//       getAllVideos(studentCourse);
//     }
//   }, [studentCourse]);
//   console.log(studentCourse, "Student Course");
//   console.log(filteredVideos, "Filtered Videos");
//   // Search function
//   const searchAllVideo = (e) => {
//     const value = e.target.value.toLowerCase();
//     const filteredExams = search.filter(
//       (videos) =>
//         videos.title.toLowerCase().includes(value) ||
//         videos.description.toLowerCase().includes(value)
//     );
//     setVideos(filteredExams);
//   };
//   const isYouTubeUrl = (url) => {
//     return url.includes("youtube.com") || url.includes("youtu.be");
//   };
//   const handlePlay = () => {
//     setIsPlaying(false);
//   };
//   const getYouTubeThumbnailUrl = (url) => {
//     const videoIdMatch = url.match(
//       /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
//     );
//     const videoId = videoIdMatch ? videoIdMatch[1] : null;
//     return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null;
//   };
//   return (
//     <div>
//       {" "}
//       <Navbar /> <PageTitle title="Courses | Test Series" />{" "}
//       <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
//         {" "}
//         <TextField
//           sx={{
//             mb: 3,
//             bgcolor: "white",
//             borderRadius: "20px",
//             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//             "& .MuiOutlinedInput-root": {
//               "& fieldset": { border: "none" },
//               "&:hover fieldset": { border: "none" },
//               "&.Mui-focused fieldset": { border: "none" },
//             },
//           }}
//           fullWidth
//           id="text"
//           name="text"
//           placeholder="Search by Title and Description"
//           onChange={searchAllVideo}
//           InputProps={{
//             startAdornment: (
//               <IconButton>
//                 <SearchIcon sx={{ color: "#1976d2" }} />
//               </IconButton>
//             ),
//           }}
//         />
//       </Container>
//       <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
//         <Box
//           sx={{
//             pt: 5,
//             pb: 5,
//             minHeight: "83vh",
//             mr: { md: 10, xs: 3 },
//             ml: { md: 18, xs: 11 },
//           }}
//         >
//           <Grid container spacing={8}>
//             {Object.keys(filteredVideos).length > 0 ? (
//               Object.keys(filteredVideos).map((instituteCourseId) => (
//                 <Box key={instituteCourseId} sx={{ mt: 4 }}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       pb: 2,
//                       fontFamily: "Poppins, sans-serif",
//                       color: "#555",
//                       letterSpacing: "2.5px",
//                       lineHeight: 1.8,
//                     }}
//                   >
//                     Course Name : {instituteCourseId}
//                   </Typography>
//                   <Grid container spacing={8}>
//                     {filteredVideos[instituteCourseId].map((video, index) => (
//                       <Grid item xs={12} sm={6} md={4} key={index}>
//                         <Badge
//                           badgeContent="Upcoming"
//                           color="primary"
//                           invisible={moment(video.startDate).isSameOrBefore(
//                             currentDate
//                           )}
//                         >
//                           <Card
//                             sx={{
//                               transition: "0.3s !important",
//                               "&:hover": { transform: "scale(1.05)" },
//                               borderRadius: "15px",
//                               boxShadow:
//                                 "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
//                               height: { xs: 400, sm: 400, md: 450 },
//                               width: "100% !important",
//                               display: "flex",
//                               flexDirection: "column",
//                               justifyContent: "space-between",
//                             }}
//                           >
//                             <CardMedia
//                               component="div"
//                               title="Video"
//                               sx={{
//                                 height: { xs: 160, sm: 200, md: 240 },
//                                 overflow: "hidden",
//                               }}
//                             >
//                               {isYouTubeUrl(video.url) ? (
//                                 <Box
//                                   onClick={handlePlay}
//                                   sx={{
//                                     height: "100%",
//                                     width: "100% !important",
//                                   }}
//                                 >
//                                   <img
//                                     src={getYouTubeThumbnailUrl(video.url)}
//                                     alt="YouTube Thumbnail"
//                                     style={{
//                                       height: "100%",
//                                       width: "100%",
//                                       objectFit: "cover",
//                                     }}
//                                   />
//                                 </Box>
//                               ) : (
//                                 <video
//                                   style={{
//                                     width: "100%",
//                                     height: "100%",
//                                     objectFit: "cover",
//                                   }}
//                                   controls={false}
//                                 >
//                                   <source src={video.url} alt="Upload Video" />
//                                 </video>
//                               )}
//                             </CardMedia>
//                             <CardContent
//                               sx={{ flexGrow: 1, padding: { xs: 1, sm: 2 } }}
//                             >
//                               {" "}
//                               {/* Adjust padding for smaller screens */}
//                               <Typography
//                                 gutterBottom
//                                 variant="h6"
//                                 component="div"
//                                 className="courses_title"
//                                 sx={{
//                                   letterSpacing: ".1rem",
//                                   fontSize: { xs: "18px", sm: "20px" },
//                                   marginBottom: "12px",
//                                   fontWeight: "bold",
//                                   fontFamily: "Poppins, sans-serif",
//                                   display: "-webkit-box",
//                                   overflow: "hidden",
//                                   WebkitBoxOrient: "vertical",
//                                   WebkitLineClamp: 2,
//                                   color: "#2C3E50",
//                                   // color: "#1E88E5",
//                                 }}
//                               >
//                                 {video.title}
//                               </Typography>
//                               <Typography
//                                 variant="body2"
//                                 color="text.secondary"
//                                 className="courses_desc"
//                                 sx={{
//                                   mt: 1,
//                                   display: "-webkit-box",
//                                   WebkitBoxOrient: "vertical",
//                                   WebkitLineClamp: 2,
//                                   overflow: "hidden",
//                                   fontSize: "15px",
//                                   letterSpacing: ".1rem",
//                                   fontFamily: "Poppins, sans-serif",
//                                 }}
//                                 dangerouslySetInnerHTML={{
//                                   __html: video.description,
//                                 }}
//                               >
//                                 {/* {video.description} */}
//                               </Typography>
//                               <Typography
//                                 className="courses_desc"
//                                 variant="body2"
//                                 color="text.secondary"
//                                 sx={{
//                                   mt: 1,
//                                   fontSize: "14px",
//                                   fontWeight: 500,
//                                   color: "#34495E",
//                                   letterSpacing: ".1rem",
//                                   fontFamily: "Poppins, sans-serif",
//                                 }}
//                               >
//                                 <strong>
//                                   {moment(video.startDate).isAfter(currentDate)
//                                     ? "Start Date:"
//                                     : "End Date:"}
//                                 </strong>{" "}
//                                 {moment(video.startDate).isAfter(currentDate)
//                                   ? new Date(video?.startDate)
//                                       .toLocaleDateString("en-GB", {
//                                         day: "2-digit",
//                                         month: "short",
//                                         year: "numeric",
//                                       })
//                                       .replace(/ /g, "-")
//                                   : new Date(video?.endDate)
//                                       .toLocaleDateString("en-GB", {
//                                         day: "2-digit",
//                                         month: "short",
//                                         year: "numeric",
//                                       })
//                                       .replace(/ /g, "-")}
//                               </Typography>
//                             </CardContent>
//                             <CardActions
//                               sx={{
//                                 mt: "auto",
//                                 padding: { xs: 1, sm: 2 },
//                                 width: "100%",
//                               }}
//                             >
//                               {moment(video.startDate).isSameOrBefore(
//                                 currentDate
//                               ) ? (
//                                 <Button
//                                   onClick={() =>
//                                     navigate(`/video/${video._id}`)
//                                   }
//                                   variant="outlined"
//                                   color="primary"
//                                   fullWidth
//                                   sx={{
//                                     mt: 1,
//                                     mb: 2,
//                                     borderRadius: "50px",
//                                     textTransform: "none",
//                                     fontFamily: "Poppins, sans-serif",
//                                     letterSpacing: ".1rem",
//                                   }}
//                                 >
//                                   Start Watching
//                                 </Button>
//                               ) : (
//                                 <Button
//                                   variant="outlined"
//                                   color="primary"
//                                   fullWidth
//                                   disabled
//                                   sx={{
//                                     mt: 1,
//                                     mb: 2,
//                                     borderRadius: "50px",
//                                     textTransform: "none",
//                                     fontFamily: "Poppins, sans-serif",
//                                     letterSpacing: ".1rem",
//                                   }}
//                                 >
//                                   Upcoming Courses
//                                 </Button>
//                               )}
//                             </CardActions>
//                           </Card>
//                         </Badge>
//                       </Grid>
//                     ))}
//                   </Grid>
//                 </Box>
//               ))
//             ) : (
//               <Box
//                 style={{
//                   color: "#878787",
//                   margin: "70px 80px",
//                   fontSize: 18,
//                 }}
//               >
//                 No Available Courses!!
//                 {/* No Available Courses. Please Contact Your Institute!! */}
//               </Box>
//             )}
//           </Grid>
//         </Box>
//       </Box>
//       <Footer />
//     </div>
//   );
// };

// export default Videos;
