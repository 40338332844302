import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Divider } from "@mui/material";
import { Link } from "react-router-dom";

const DemoFooter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const currentYear = new Date().getFullYear();
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      <Box
        className="footer-main-box"
        sx={{
          backgroundColor: "#252C39",
          width: "100%",
          height: "100%",
          padding: "20px 0",
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                className="footer-main-inside-box"
                sx={{ m: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}
              >
                {/* Added links section */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "Left",
                    mb: 2,
                    color: "#fff",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      color: "#FFF",
                      textDecoration: "none",
                      margin: "8px",
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    to="/testseries/about"
                    style={{
                      color: "#FFF",
                      textDecoration: "none",
                      margin: "8px",
                    }}
                  >
                    About
                  </Link>
                  <Link
                    to="/testseries/plan"
                    style={{
                      color: "#FFF",
                      textDecoration: "none",
                      margin: "8px",
                    }}
                  >
                    Plan
                  </Link>
                  <Link
                    to="/testseries/contact"
                    style={{
                      color: "#FFF",
                      textDecoration: "none",
                      margin: "8px",
                    }}
                  >
                    Contact
                  </Link>
                  <Link
                    style={{
                      color: "#FFF",
                      textDecoration: "none",
                      margin: "8px",
                    }}
                    
                     to="/testseries/refund-policy"
                  >
                    Refund Policy
                  </Link>
                  <Link
                    style={{
                      color: "#FFF",
                      textDecoration: "none",
                      margin: "8px",
                    }}
                   to="/testseries/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    style={{
                      color: "#FFF",
                      textDecoration: "none",
                      margin: "8px",
                    }}
                    to="/testseries/terms-service"
                  >
                    Terms and Conditions
                  </Link>
                </Box>
                {/* End of added links section */}
                <Divider sx={{ backgroundColor: "#fff", height: "2px" }} />
                <Box
                  className="footer-under-section"
                  sx={{
                    mt: 3,
                    mb:5,
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "1.75",
                  }}
                >
                  <p>
                    GatikAI Technologies is one of the growing IT Solutions
                    company based in India. We provides expert solutions in the
                    area of App & Web development.
                  </p>
                  <p>
                    &copy; {currentYear} Copyright by{" "}
                    <Link
                      to="https://gatiktech.com"
                      style={{ color: "#FFF", textDecoration: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GatikAI Technologies
                    </Link>
                    . All rights reserved.
                  </p>
                  <p>
                    Registered Office: Arg group, north avenue, 306A, third
                    floor, road no 9, Sikar Rd, Jaipur, Rajasthan 302013
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

      </Box>
    </div>
  );
};

export default DemoFooter;
