/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from "react";
import {
  Container,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../axiosInstance";
import { useParams } from "react-router-dom";
import Papa from "papaparse";
import AddIcon from "@mui/icons-material/Add";
import { message, Modal, Select } from "antd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from "@mui/material/LinearProgress";
import MicIcon from "@mui/icons-material/Mic";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from "browser-image-compression";
import DownloadCSV from "./DownloadCSV";

const { Option } = Select;
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

// Utility function to convert file to base64
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const AddQuestions = ({ vendor_Id, institute_Id }) => {
  const fileInputRef = useRef(null);
  const [instituteId, setInstituteId] = useState(institute_Id);
  const [vendorId, setVendorId] = useState(vendor_Id);
  const [csvFile, setCsvFile] = useState(null);
  const [inputType, setInputType] = useState("options");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isRecognitionActive, setIsRecognitionActive] = useState(false);
  const [language, setLanguage] = useState("en-IN");
  const [addQuestionName, setAddQuestionName] = useState("");
  const [questionNameImages, setQuestionNameImages] = useState([]);
  const [options, setOptions] = useState([
    { text: "", images: [] },
    { text: "", images: [] },
    { text: "", images: [] },
    { text: "", images: [] },
  ]);
  const [correctOption, setCorrectOption] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [loading, setLoading] = useState(false);

  // Utility function to check image size before uploading
  const MAX_IMAGE_SIZE = 500 * 1024;

  const handleImagesChange = async (event, setImages) => {
    const files = Array.from(event.target.files);
    const compressedImages = await Promise.all(
      files.map(async (file) => {
        if (file.type.startsWith("image/")) {
          if (file.size > MAX_IMAGE_SIZE) {
            message.warning("File size exceeds the 500 KB limit.");
            return null;
          }
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800,
          });
          return convertFileToBase64(compressedFile);
        } else {
          message.warning("Only image files are allowed");
          return null;
        }
      })
    );
    setImages((prevImages) => [
      ...prevImages,
      ...compressedImages.filter(Boolean),
    ]);
  };

  const handleOptionImagesChange = async (e, index) => {
    const files = Array.from(e.target.files);

    const base64Images = await Promise.all(
      files.map(async (file) => {
        if (file.type.startsWith("image/")) {
          if (file.size > MAX_IMAGE_SIZE) {
            message.warning("File size exceeds the 500 KB limit.");
            return null;
          }
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800,
          });
          return convertFileToBase64(compressedFile);
        } else {
          message.warning("Only image files are allowed.");
          return null;
        }
      })
    );

    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index].images = [
        ...(updatedOptions[index].images || []),
        ...base64Images.filter(Boolean),
      ];
      return updatedOptions;
    });
  };

  const handleRemoveImage = (index, setImages) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveOptionImage = (optionIndex, imageIndex) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[optionIndex].images.splice(imageIndex, 1);
      return updatedOptions;
    });
  };

  const handleAddMore = () => {
    setOptions((prevOptions) => [...prevOptions, { text: "", images: [] }]);
  };
  const { confirm } = Modal;
  const handleDeleteInputs = (index) => {
    confirm({
      title: "Are you sure you want to delete this option?",
      content:
        "Deleting this option will remove it permanently. Please confirm if you want to proceed.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: () => {
        setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
      },
      onCancel: () => {
        // Do nothing if the user cancels
        console.log("Deletion cancelled");
      },
    });
  };

  const handleCheckboxChange = (index) => {
    setCorrectOption((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Extracting id from useParams and localStorage
  const { id: paramId } = useParams();
  const localStorageId = JSON.parse(localStorage.getItem("id"));
  const examId = paramId || localStorageId;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
    handleCsvUpload(file);
  };
// Handle Upload CSV File
  const handleCsvUpload = (file) => {
    setIsUploading(true);
    setUploadProgress(0);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (parsed) => {
        if (parsed.errors.length > 0) {
          console.error("Parsing error:", parsed.errors);
          setIsUploading(false);
          return;
        }

        if (parsed.data.length > 0) {
          const formattedQuestions = parsed.data.map((row) => {
            const options = [];
            const optionKeys = Object.keys(row).filter((key) =>
              key.startsWith("Option")
            );
            optionKeys.forEach((key) => {
              const optionText = row[key]?.trim().replace(/[.\s]+$/, "");
              if (optionText) {
                options.push({ text: optionText });
              }
            });

            const correctOptions = row["Correct Answer"]
              .split(",")
              .map((answer) => answer.trim().charAt(0).toLowerCase())
              .map((letter) => letter.charCodeAt(0) - 97);

            const questionNameImages = [];
            if (row["Image URL"]) {
              questionNameImages.push({
                type: "image",
                content: row["Image URL"],
              });
            }

            const formattedQuestionName = [
              { type: "text", content: row.Question.trim() },
              ...questionNameImages,
            ];
            return {
              name: formattedQuestionName,
              correctOption: correctOptions,
              options,
              exam: examId,
              instituteId: instituteId,
              vendorId: vendorId,
            };
          });

          try {
            const response = await axios.post(
              "/api/v1/question/admin-add-exam-to-csv-questions",
              {
                questions: formattedQuestions,
                exam: examId,
                instituteId: instituteId,
                vendorId: vendorId,
              },
              {
                onUploadProgress: (progressEvent) => {
                  const progress =
                    (progressEvent.loaded / progressEvent.total) * 100;
                  setUploadProgress(progress);
                },
              }
            );

            if (response?.data?.success) {
              message.success(response?.data?.message);
              fileInputRef.current.value = null; // Reset file input
            } else {
              message.error(response?.data?.message);
            }
          } catch (error) {
            message.error(error?.response?.data?.message);
          } finally {
            setIsUploading(false);
          }
        }
      },
    });
  };
  
  const handleSpeechInput = (field, index) => {
    if (isRecognitionActive) {
      message.warning("Speech recognition is already in progress!");
      return;
    }
  
    // Show the modal only for the "question" field
    if (field === "question") {
      Modal.confirm({
        title: "Start Speech Recognition?",
        content: "Are you sure you want to start speech recognition?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          recognition.lang = language;
          setIsRecognitionActive(true);
          recognition.start();
          recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript.trim();
            if (field === "question") {
              setAddQuestionName((prev) => prev + " " + transcript);
            }
          };
          recognition.onend = () => {
            setIsRecognitionActive(false);
            message.success("Speech recognition ended.");
          };
          recognition.onerror = (event) => {
            setIsRecognitionActive(false);
            message.error("Speech recognition error: " + event.error);
          };
        },
        onCancel: () => {
          message.info("Speech recognition cancelled.");
        },
      });
    } else if (field === "option") {
      // Directly start recognition without showing the modal for "option"
      recognition.lang = language;
      setIsRecognitionActive(true);
      recognition.start();
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript.trim();
        const updatedOptions = [...options];
        updatedOptions[index].text += " " + transcript;
        setOptions(updatedOptions);
      };
      recognition.onend = () => {
        setIsRecognitionActive(false);
        message.success("Speech recognition ended.");
      };
      recognition.onerror = (event) => {
        setIsRecognitionActive(false);
        message.error("Speech recognition error: " + event.error);
      };
    }
  };
  

  // Add Questions
  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!addQuestionName.trim()) {
      message.error("Please enter a Question Name.");
      setLoading(false);
      return;
    }

    if (options.length === 0) {
      message.error("Please add at least one Option.");
      setLoading(false);
      return;
    }

    if (correctOption.length === 0) {
      message.error("Please select at least one Correct Option.");
      setLoading(false);
      return;
    }

    const addQuestionAndImage = [
      { type: "text", content: addQuestionName.trim() },
      ...questionNameImages.map((src) => ({ type: "image", content: src })),
    ];

    const filteredCorrectOptions = correctOption.filter(
      (index) => typeof index === "number"
    );

    if (filteredCorrectOptions.length === 0) {
      message.error("Please select valid correct options.");
      setLoading(false);
      return;
    }

    const questionPayload = {
      name: addQuestionAndImage,
      correctOption: filteredCorrectOptions,
      options,
      exam: examId,
      instituteId,
      vendorId,
    };

    try {
      const { data } = await axios.post(
        "/api/v1/question/admin-add-exam-to-questions",
        questionPayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        message.success(data.message);
        setAddQuestionName("");
        setQuestionNameImages([]);
        setCorrectOption([]);
        setOptions([
          { text: "", images: [] },
          { text: "", images: [] },
          { text: "", images: [] },
          { text: "", images: [] },
        ]);
      } else {
        message.error(data.message || "Failed to add the question.");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message ||
          "An error occurred while adding the question."
      );
    }finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Box sx={{ pb: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm="auto">
            <Box>{csvFile?.name}</Box>
          </Grid>
          <input
            id="csv-file-input"
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <Grid item xs={12} sm="auto">
            <Button
              startIcon={<CloudUploadIcon />}
              onClick={() => fileInputRef.current.click()}
              variant="outlined"
              component="span"
              color="primary"
              className="courses_desc"
              sx={{
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Open and Upload CSV File
            </Button>
          </Grid>
          {isUploading && (
            <Grid item xs={12} sm="auto">
              <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm="auto">
            <Box mb={-2}>
              <DownloadCSV />
            </Box>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Select
              value={language}
              onChange={(value) => setLanguage(value)}
              style={{ width: 200 }}
            >
              <Option value="en-IN">English (India)</Option>
              <Option value="hi-IN">Hindi (India)</Option>
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Container
        onSubmit={handleSubmitQuestion}
        component="form"
        className="form_style border-style"
        maxWidth
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <ReactQuill
            id="name"
            label="Question"
            name="name"
            placeholder="Question"
            value={addQuestionName}
            onChange={setAddQuestionName}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              onClick={() => handleSpeechInput("question")}
              startIcon={<MicIcon />}
            >
              Speak
            </Button>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => handleImagesChange(e, setQuestionNameImages)}
              style={{ display: "none" }}
              id={`image-upload`}
            />
            <label htmlFor={`image-upload`}>
              <Button variant="outlined" component="span">
                Choose File
              </Button>
            </label>
            <Box display="flex" gap={1} flexWrap="wrap">
              {questionNameImages?.map((src, index) => (
                <Box key={index} position="relative" display="inline-block">
                  <img
                    src={src}
                    alt={`Title Preview ${index + 1}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "4px",
                    }}
                  />
                  <IconButton
                    onClick={() =>
                      handleRemoveImage(index, setQuestionNameImages)
                    }
                    color="error"
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <br />
        {inputType === "options" && (
          <Grid container spacing={4} rowSpacing={2} columnSpacing={4}>
            {options?.map((option, i) => (
              <Grid item xs={12} sm={6} md={6} key={i}>
                <Box sx={{ mb: 2 }}>
                  <ReactQuill
                    theme="snow"
                    id={`option-${i}`}
                    placeholder={`Option ${i + 1}`}
                    value={option.text}
                    onChange={(value) => {
                      const updatedOptions = [...options];
                      updatedOptions[i] = { ...updatedOptions[i], text: value };
                      setOptions(updatedOptions);
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={correctOption.includes(i)}
                          onChange={() => handleCheckboxChange(i)}
                          name={`correct-option-${i}`}
                        />
                      }
                      label="Correct Option"
                      sx={{ marginRight: 2 }}
                    />
                    {options?.length > 1 && (
                      <Button
                        sx={{ fontSize: "14px" }}
                        onClick={() => handleDeleteInputs(i)}
                        color="error"
                      >
                        Remove
                      </Button>
                    )}
                    <Button
                      onClick={() => handleSpeechInput("option", i)}
                      startIcon={<MicIcon />}
                      sx={{ ml: 2 }}
                    >
                      Speak
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mt: 2,
                        flexWrap: "wrap",
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => handleOptionImagesChange(e, i)}
                          style={{ display: "none" }}
                          id={`image-upload-${i}`}
                        />
                        <label htmlFor={`image-upload-${i}`}>
                          <Button
                            sx={{ mt: -2 }}
                            variant="outlined"
                            component="span"
                          >
                            Choose File
                          </Button>
                        </label>
                        <Box display="flex" gap={1} sx={{ mt: -2 }}>
                          {option?.images.map((src, imgIndex) => (
                            <Box
                              key={imgIndex}
                              position="relative"
                              display="inline-block"
                            >
                              <img
                                src={src}
                                alt={`Option ${i + 1} Image ${imgIndex + 1}`}
                                style={{ width: "50px", height: "50px" }}
                              />
                              <IconButton
                                onClick={() =>
                                  handleRemoveOptionImage(i, imgIndex)
                                }
                                color="error"
                                size="small"
                                style={{ top: 0, right: 0 }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    {i + 1 === options?.length && (
                      <Button
                        variant="outlined"
                        sx={{ mb: 2, mt: 2 }}
                        onClick={handleAddMore}
                        color="success"
                        startIcon={<AddIcon />}
                      >
                        Add More Option
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <br />
        <Button
         disabled={loading}
          variant="contained"
          type="submit"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 0,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Add Question"}
        </Button>
      </Container>
    </div>
  );
};

export default AddQuestions;
