import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Breadcrumbs,
  Link as MUILink,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import { message } from "antd";
import FilterListIcon from "@mui/icons-material/FilterList";
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";

const PaymentDetails = () => {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const getPaymentDetails = async () => {
    try {
      const response = await axios.get("/api/admin-get-payment-details");
      setPaymentDetails(response.data.getPayment);
      setFilteredRows(response.data.getPayment);
    } catch (error) {
      console.error("Error fetching payment details:", error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    message.success(`Copied Txn ID: ${value}`);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filtered = paymentDetails.filter(
      (row) =>
        row.transactionID?.toLowerCase().includes(value) ||
        row.email?.toLowerCase().includes(value)
    );
    setFilteredRows(filtered);
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setStatusFilter(selectedStatus);
    filterData(searchText, selectedStatus); // Call filterData with search and status filter
  };

  const filterData = (search, status) => {
    const filtered = paymentDetails.filter((row) => {
      const matchesSearch =
        row.transactionID?.toLowerCase().includes(search) ||
        row.email?.toLowerCase().includes(search);
      const matchesStatus = status ? row.status === status : true;
      return matchesSearch && matchesStatus;
    });
    setFilteredRows(filtered);
  };

  const calculateStatusTotals = (rows) => {
    const totals = {
      SUCCESS: { amount: 0, count: 0 },
      PENDING: { amount: 0, count: 0 },
      FAILED: { amount: 0, count: 0 },
      TOTAL: { amount: 0, count: 0 },
    };

    rows.forEach((row) => {
      if (row.status === "SUCCESS") {
        totals.SUCCESS.amount += row.amount;
        totals.SUCCESS.count += 1;
      }
      if (row.status === "PENDING") {
        totals.PENDING.amount += row.amount;
        totals.PENDING.count += 1;
      }
      if (row.status === "FAILED") {
        totals.FAILED.amount += row.amount;
        totals.FAILED.count += 1;
      }
      totals.TOTAL.amount += row.amount;
      totals.TOTAL.count += 1;
    });

    return totals;
  };

  const statusTotals = calculateStatusTotals(filteredRows);

  // To format the amount with commas
  const formattedTotal = statusTotals.TOTAL.amount.toLocaleString();

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 10,
      sortable: false,
    },
    {
      field: "transactionID",
      headerName: "Transaction ID",
      width: 220,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ marginRight: 1, fontSize: "15px" }}>
            {params.row.transactionID}
          </Typography>
          <Tooltip title="Copy Transaction ID">
            <IconButton onClick={() => handleCopy(params.row.transactionID)}>
              <ContentCopyIcon
                sx={{ fontSize: "20px", color: "rgb(25, 118, 210)" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      renderCell: (params) => {
        const statusStyles = {
          SUCCESS: {
            color: "green",
            fontWeight: "bold",
            backgroundColor: "rgba(0, 128, 0, 0.1)",
            borderRadius: "12px",
            padding: "5px 10px",
          },
          PENDING: {
            color: "orange",
            fontWeight: "bold",
            backgroundColor: "rgba(255, 165, 0, 0.1)",
            borderRadius: "12px",
            padding: "5px 10px",
          },
          FAILED: {
            color: "red",
            fontWeight: "bold",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            borderRadius: "12px",
            padding: "5px 10px",
          },
        };

        return (
          <Typography
            sx={{
              ...statusStyles[params.row.status],
              fontSize: "13px",
            }}
          >
            {params.row.status}
          </Typography>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      editable: true,
      renderCell: (params) => {
        const amountStyles = {
          Standard: {
            color: "#43A047",
            backgroundColor: "rgba(67, 160, 71, 0.1)",
            borderRadius: "8px",
            padding: "4px 8px",
            fontWeight: "bold",
          },
          Pro: {
            color: "#FFB300",
            backgroundColor: "rgba(255, 179, 0, 0.1)",
            borderRadius: "8px",
            padding: "4px 8px",
            fontWeight: "bold",
          },
          Elite: {
            color: "#FF3D00",
            backgroundColor: "rgba(255, 61, 0, 0.1)",
            borderRadius: "8px",
            padding: "4px 8px",
            fontWeight: "bold",
          },
        };
        const planType = params.row.requestedPlan;
        return (
          <Typography
            sx={{
              ...(amountStyles[planType] || {}),
              fontSize: "14px",
            }}
          >
            {params.row.amount}
          </Typography>
        );
      },
    },
    {
      field: "requestedPlan",
      headerName: "Requested Plan",
      width: 150,
      renderCell: (params) => {
        const planStyles = {
          Standard: {
            color: "#1E88E5",
            fontWeight: "bold",
            backgroundColor: "rgba(30, 136, 229, 0.1)",
            borderRadius: "12px",
            padding: "5px 10px",
          },
          Pro: {
            color: "#43A047",
            fontWeight: "bold",
            backgroundColor: "rgba(67, 160, 71, 0.1)",
            borderRadius: "12px",
            padding: "5px 10px",
          },
          Elite: {
            color: "#FFB300",
            fontWeight: "bold",
            backgroundColor: "rgba(255, 179, 0, 0.1)",
            borderRadius: "12px",
            padding: "5px 10px",
          },
        };

        return (
          <Typography
            sx={{
              ...planStyles[params.row.requestedPlan],
              fontSize: "14px",
            }}
          >
            {params.row.requestedPlan}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 240,
      editable: true,
    },
    {
      field: "number",
      headerName: "Phone",
      width: 150,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Payment Date",
      width: 150,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <Box>
      <PageTitle title="Admin Payment Details | Test Series" />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Payment Details
      </Typography>

      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/transactions"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Transactions
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Payment Details</Typography>
      </Breadcrumbs>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Filter</InputLabel>
            <Select
              value={statusFilter}
              label="Filter"
              onChange={handleStatusChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="SUCCESS">Success</MenuItem>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="FAILED">Failed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Vendor Name or Vendor Email"
            value={searchText}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={3}>
          <Card sx={{ textAlign: "center", bgcolor: "green", color: "#fff" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Success Transactions
              </Typography>
              <Typography variant="h5">
                Total {statusTotals.SUCCESS.count}{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ textAlign: "center", bgcolor: "orange", color: "#fff" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Pending Transactions
              </Typography>
              <Typography variant="h5">
                Total {statusTotals.PENDING.count}{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ textAlign: "center", bgcolor: "red", color: "#fff" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Failed Transactions
              </Typography>
              <Typography variant="h5">
                Total {statusTotals.FAILED.count}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ textAlign: "center", bgcolor: "blue", color: "#fff" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Total Payments
              </Typography>
              <Typography variant="h5"> ₹ {formattedTotal}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 650, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              // Style for header cells
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={filteredRows.map((vendor, index) => ({
              id: vendor._id,
              rowNumber: index + 1,
              ...vendor,
            }))}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default PaymentDetails;
