import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import { styled } from "@mui/material/styles";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InstituteInfoList from "./InstituteInfoList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InstituteInfo() {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [files, setFiles] = useState([]);
  const [instituteId, setInstituteId] = useState(user?.instituteId);
  const [vendorId, setVendorId] = useState(user?._id);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [instituteName, setInstituteName] = useState("");
  const [instituteDescription, setInstituteDescription] = useState("");
  const [instituteEmail, setInstituteEmail] = useState("");
  const [institutePhone, setInstitutePhone] = useState("");
  const [instituteAddress, setInstituteAddress] = useState("");
  const [filePreviews, setFilePreviews] = useState([]);
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);

    // Create an array of object URLs
    const fileURLs = Array.from(selectedFiles).map((file) => {
      const objectUrl = URL.createObjectURL(file);
      return objectUrl;
    });

    // Update file previews with the object URLs
    setFilePreviews(fileURLs);
  };

  // Cleanup object URLs when component unmounts or file previews change
  useEffect(() => {
    return () => {
      filePreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [filePreviews]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    for (const file of files) {
      formData.append("banners", file);
    }
    formData.append("instituteId", instituteId);
    formData.append("vendorId", vendorId);

    try {
      const response = await axios.post(
        "/api/v1/vendor/admin-upload-banner-institute",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(response.data.message);
      getAllBannerImage(); 
    } catch (error) {
      message.error(error.response.data.message || "Error uploading files");
    } finally {
      setLoading(false);
    }
  };

  const getAllBannerImage = async () => {
    try {
      const response = await axios.get(
        "/api/v1/vendor/admin-get-all-banner-institute"
      );
      if (response?.data?.success === true) {
        setBanner(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllBannerImage();
  }, []);

  // Delete Banner Images
  const { confirm } = Modal;
  const deleteBannerImage = async (id, cloudinaryId) => {
    confirm({
      title: "Are you sure you want to delete this institute banner image?",
      content: "This action will remove the banner image permanently.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      okType: "danger",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/vendor/admin-delete-banner-institute",
            {
              data: { id, cloudinaryId },
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            getAllBannerImage();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error deleting banner image."
          );
        }
      },
      onCancel() {
        console.log("Banner image deletion canceled");
      },
    });
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={() =>
            deleteBannerImage(params?.row?._id, params?.row?.cloudinaryId)
          }
        >
          <DeleteIcon sx={{ color: "red" }} />
        </IconButton>
      ),
    },
    // {
    //   field: "instituteId",
    //   headerName: "Institute Id",
    //   width: 180,
    //   // editable: true,
    // },
    {
      field: "cloudinaryId",
      headerName: "Cloudinary Id",
      width: 200,
      editable: true,
    },
    {
      field: "url",
      headerName: "Banner Image",
      width: 240,
      renderCell: (params) => (
        <img
          src={params.row.url}
          alt="Banner"
          style={{ width: "100px", height: "auto", borderRadius: "5px" }}
        />
      ),
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(1, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  // Add Institute Information
  const instituteSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("instituteName", instituteName);
    formData.append("description", instituteDescription);
    formData.append("vendorId", user?._id);
    formData.append("instituteId", user?.instituteId);
    formData.append("instituteEmail", instituteEmail);
    formData.append("institutePhone", institutePhone);
    formData.append("instituteAddress", instituteAddress);
    formData.append("instituteLogo", imageBase64);

    try {
      const { data } = await axios.post(
        "/api/v1/vendor/admin-about-institute",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.success === true) {
        message.success(data.message);
        setInstituteName("");
        setInstituteDescription("");
        setInstituteAddress("");
        setInstitutePhone("");
        setImageBase64(null);
        setImage(null);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // Upload button functionality
  const VisuallyHiddenInput = styled("input")({
    display: "none",
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      // Convert image to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <PageTitle title="Admin Institute Information| Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Institute Information
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 3,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            {" "}
            Institute Information
          </Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Home" {...TabPanel(0)} />
            <Tab label="About List" {...TabPanel(1)} />
            <Tab label="Add About" {...TabPanel(2)} />
          </Tabs>
        </Box>

        {/* Institute Banner Image */}
        <CustomTabPanel value={value} index={0}>
          <Box>
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                pt: 3,
                fontFamily: "Poppins, sans-serif",
                color: "#555",
                letterSpacing: "2.5px",
                lineHeight: 1.8,
              }}
            >
              Upload Institute Banner Images
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Institute ID"
                value={instituteId}
                onChange={(e) => setInstituteId(e.target.value)}
                fullWidth
                margin="normal"
                required
                sx={{ mb: 2, display: "none" }}
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Files
                </Button>
              </label>
              <Box mt={2}>
                {files.length > 0 && (
                  <Typography variant="body2">{`${files.length} file(s) selected`}</Typography>
                )}
              </Box>
              <Box mt={2}>
                <Button
                  disabled={loading}
                  variant="contained"
                  type="submit"
                  className="courses_desc"
                  sx={{
                    mt: 2,
                    color: "primary",
                    mb: 3,
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: "Poppins, sans-serif",
                    letterSpacing: ".1rem",
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </Box>
            </form>
            {/* Image Previews */}
            <Box mt={3}>
              {filePreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`preview-${index}`}
                  style={{ width: "100px", marginRight: "10px" }}
                />
              ))}
            </Box>
            <hr />
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                pt: 3,
                fontFamily: "Poppins, sans-serif",
                color: "#555",
                letterSpacing: "2.5px",
                lineHeight: 1.8,
              }}
            >
              Institute Banner Image Details
            </Typography>
            <Paper sx={{ bgcolor: "white" }}>
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row?._id}
                  sx={{
                    "& .MuiTablePagination-displayedRows": {
                      color: "black",
                    },
                    color: "black",
                    [`& .${gridClasses?.row}`]: {
                      bgcolor: "white",
                    },
                    // Style for header cells
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "rgb(25, 118, 210)",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      color: "white",
                      fontWeight: "bold",
                      // fontSize: "13px",
                    },
                    // Style for checkbox in header
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                      color: "#FFF",
                    },
                    "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiDataGrid-sortIcon": {
                      color: "white",
                    },
                  }}
                  rows={banner}
                  columns={columns}
                  pageSize={3}
                  rowsPerPageOptions={[3]}
                  // checkboxSelection
                />
              </Box>
            </Paper>
          </Box>
        </CustomTabPanel>

        {/* About Institute Details List */}
        <CustomTabPanel value={value} index={1}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Institute Details List
          </Typography>
          <InstituteInfoList />
        </CustomTabPanel>

        {/* Create Institute Details */}
        <CustomTabPanel value={value} index={2} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Add Institute Details
          </Typography>
          <Container
            onSubmit={instituteSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteName"
              label="Institute Name"
              name="instituteName"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Institute Name"
              value={instituteName}
              onChange={(e) => setInstituteName(e.target.value)}
            />

            <h4 style={{ color: "#2C3E50" }}>Short Description In Institute</h4>
            <ReactQuill
              value={instituteDescription}
              onChange={setInstituteDescription}
              placeholder="Short Description In Institute"
              style={{
                // height: "200px",
                marginBottom: "30px",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "10px",
              }}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              type="email"
              id="instituteEmail"
              label="Institute Email"
              name="instituteEmail"
              InputLabelProps={{
                shrink: true,
              }}
              required
              margin="normal"
              placeholder="Institute Email"
              value={instituteEmail}
              onChange={(e) => setInstituteEmail(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              type="number"
              id="institutePhone"
              label="Institute Phone Number"
              name="institutePhone"
              InputLabelProps={{
                shrink: true,
              }}
              required
              margin="normal"
              placeholder="Institute Phone Number"
              value={institutePhone}
              onChange={(e) => setInstitutePhone(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteAddress"
              label="Institute Address"
              name="instituteAddress"
              InputLabelProps={{
                shrink: true,
              }}
              required
              margin="normal"
              multiline
              placeholder="Institute Address"
              value={instituteAddress}
              onChange={(e) => setInstituteAddress(e.target.value)}
            />

            <Box sx={{ mt: 3 }}>
              <VisuallyHiddenInput
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Institute logo
                </Button>
              </label>
              {image && (
                <Box mt={3} display="flex" alignItems="center">
                  <img
                    src={image}
                    alt="avatar"
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </Box>
              )}
            </Box>
            <br />
            <Button
              disabled={loading}
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Add Institute Info"}
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
